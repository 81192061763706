import { Command, CommandHandler, ProjectImpl, StateUpdater } from '@grenton/gm-logic';
import { FirmwareProvider } from '../firmwareProvider';

export class EditProjectSettingsCommand {
    readonly type = 'EditProjectSettingsCommand';
    constructor(readonly data: ProjectSettingsFormData) {}
}

export type ProjectSettingsFormData = {
    label: string;
    firmware: string;
};

export class EditProjectSettingsCommandHandler implements CommandHandler<EditProjectSettingsCommand> {
    constructor(
        private update: StateUpdater<ProjectImpl>,
        private firmwareProvider: FirmwareProvider,
    ) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'EditProjectSettingsCommand';
    }

    execute(cmd: EditProjectSettingsCommand): void {
        this.update((project) => {
            if (project.firmware.version !== cmd.data.firmware) {
                const firmware = this.firmwareProvider(cmd.data.firmware);
                // TODO implement migration
                if (!firmware) {
                    throw new Error(`Firmware "${cmd.data.firmware}" is not supported`);
                }
                project = project.withFirmware(firmware);
            }
            return project.withLabel(cmd.data.label);
        });
    }
}
