import { Command, CommandHandler } from '../../dispatcher';
import { ProjectImpl } from '../../project';
import { StateUpdater } from '../../utils/state';

export class MoveObjectsBetweenModulesCommand implements Command {
    readonly type = 'MoveObjectsBetweenModulesCommand';
    constructor(readonly data: { sourceObjectId: string; targetObjectId: string }) {}
}

export class MoveObjectsBetweenModulesCommandHandler implements CommandHandler<MoveObjectsBetweenModulesCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command) {
        return cmd.type === 'MoveObjectsBetweenModulesCommand';
    }

    execute(cmd: MoveObjectsBetweenModulesCommand) {
        this.update((p) => {
            const source = p.getObjectById(cmd.data.sourceObjectId);
            const target = p.getObjectById(cmd.data.targetObjectId);
            if (!source || !target || source.api.name !== target.api.name) return p;
            const sourceModuleRef = source.impl;
            const targetModuleRef = target.impl;
            if (sourceModuleRef.type !== 'module' || targetModuleRef.type !== 'module') return p;
            return p.withObject(source.withModuleRef(targetModuleRef.componentRef)).withObject(target.withModuleRef(sourceModuleRef.componentRef));
        });
    }
}
