import { Command, CommandHandler, StateUpdater, installCertificateCommandType } from '../../project';

export class CertificateInstallerHandler implements CommandHandler<{}> {
    constructor(private updater: StateUpdater<object | null>) {}

    supports(cmd: Command): boolean {
        return cmd.type === installCertificateCommandType;
    }

    // we cannot use boolean cuz we it would be triggered only once
    execute(_: {}): void | Promise<void> {
        this.updater(() => new Date());
    }
}
