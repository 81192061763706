import { Command, CommandHandler } from '@grenton/gm-logic';
import { GMUser } from './user';
import { UserHolder } from './userholder';
import { Token } from './token';
import { TokenHolder } from './tokenholder';

// it is used here as an event, which I dislike
export class UserAuthenticationCommand implements Command {
    constructor(public user: GMUser | null) {}
    readonly type = 'UserAuthenticationCommand';
}

export class UserAuthenticationCommandHandler implements CommandHandler<UserAuthenticationCommand> {
    constructor(private userHolder: UserHolder) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'UserAuthenticationCommand';
    }
    execute(cmd: UserAuthenticationCommand): void {
        this.userHolder.user.next(cmd.user);
    }
}

export class UserTokenCommand implements Command {
    constructor(public token: Token | null) {}
    readonly type = 'UserTokenCommand';
}

export class UserTokenCommandHandler implements CommandHandler<UserTokenCommand> {
    constructor(private tokenHolder: TokenHolder) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'UserTokenCommand';
    }

    execute(cmd: UserTokenCommand): void {
        this.tokenHolder.token.next(cmd.token);
    }
}
