import { Stack, List, ListItemButton, ListItemIcon, Checkbox, ListItemText, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import GMTextField from "../../../../ui/controls/GMTextField"
import { ActionApiModel } from "./model"
import { ObjectApi } from "@grenton/gm-common"

export function ActionApiWidget(props:{
    api:ObjectApi,
    model:ActionApiModel,
    onUpdate:(model:ActionApiModel)=>void}) {
    const [selected, setSelected] = useState<string>("")
    
    const {model,api,onUpdate} = props

    const items = [
        ...Object.values(api.flat.features).filter(m=>!m.readOnly).map(item=>({type:'feature', params:[{id:'value',unit:item.unit}], item})), 
        ...Object.values(api.flat.methods).filter(m=>!m.internal).map(item=>({type:'method', params:item.params, item}))
    ]

    const selectedItem = items.find(m=>m.item.id===selected)

    useEffect(()=>{
        setSelected(items.find(m=>Boolean(model.callables[m.item.id]?.enabled))?.item.id||'')
    }, [api])

    return <Stack height={200} direction="row">
        <List sx={{width:300, overflow:'auto'}}>
        {items.map(m=>{
            const checked = Boolean(model.callables[m.item.id]?.enabled)
            return <ListItemButton selected={selected===m.item.id} key={m.item.id} onClick={()=>{setSelected(m.item.id)}} dense>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={checked}
                    tabIndex={-1}
                    disableRipple
                    // onClick={(e)=>{if (checked) e.stopPropagation()}}
                    onChange={(_,enabled)=>{
                        const callables = {...model.callables, [m.item.id]:{...model.callables[m.item.id], enabled}}
                        onUpdate({...model, callables})
                    }}
                />
            </ListItemIcon>
            <ListItemText sx={{opacity:checked ? 1 : 0.5}} primary={m.item.label||m.item.id} />
            </ListItemButton>

        })}
        </List>
        <Stack p={2} spacing={2} sx={{borderLeft:'solid', borderColor:'#eee'}} direction="column">
           { selectedItem ? selectedItem.params?.map(p=><GMTextField 
                size="small" 
                disabled={!Boolean(model.callables[selectedItem.item.id]?.enabled)} 
                key={p.id} 
                value={(model.callables[selectedItem.item.id]?.params||{})[p.id]||''}
                label={`${p.id} ${p.unit||''}`}
                onChange={(e)=>{
                    let m = {...model, methods: {...model.callables, [selectedItem.item.id] : {...model.callables[selectedItem.item.id], params:{ ...model.callables[selectedItem.item.id]?.params, [p.id]:e.target.value} } }}
                    onUpdate(m)
                }}
                />) : null }
            { selectedItem && !Boolean(selectedItem?.params?.length) ? <Typography variant="caption" color="GrayText">method has no parameters</Typography> : null}
        </Stack>
    </Stack>
}
