import { ProjectObject, schema, OUTLET_PARENT, ObjectApiImpl } from '@grenton/gm-common';
import {
    ProjectFirmwareImpl,
    ProjectObjectImpl,
    isAnonymousController,
    SelectedTagsImpl,
    ProjectObjectApi,
    ProjectObjectInitImpl,
    ProjectScriptsMutable,
    ProjectScriptsEmpty,
} from '../model';

export function importObject(firmware: ProjectFirmwareImpl, o: ProjectObject): ProjectObjectImpl {
    const api = firmware.resolveObjectApi(o.id, o.api);
    if (!api) throw schema.noSuchApiError(o.api);
    const top = !api.flat.outlets[OUTLET_PARENT] && !isAnonymousController(o.id);
    return new ProjectObjectImpl({
        uuid: o.id,
        top,
        label: o.label,
        tags: SelectedTagsImpl.from(o.tags),
        api: new ProjectObjectApi(api as ObjectApiImpl, o.impl.type === 'script'),
        init: ProjectObjectInitImpl.from(o.init || {}),
        scripts: o.impl.type === 'script' ? ProjectScriptsMutable.from(o.scripts || []) : new ProjectScriptsEmpty(),
        deviceType: o.deviceType,
        impl: o.impl,
        accessLevel: o.accessLevel,
    });
}
