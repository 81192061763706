import { Command, CommandHandler, ProjectImpl, StateUpdater } from '@grenton/gm-logic';

export class I18nLangDeleteCommand implements Command {
    readonly type = 'i18nLangDeleteCommand';

    constructor(readonly code: string) {}
}

export class I18nLangDeleteCommandHandler implements CommandHandler<I18nLangDeleteCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd instanceof I18nLangDeleteCommand;
    }

    execute(cmd: I18nLangDeleteCommand): void {
        this.update((p) => p.withoutI18nLang(cmd.code));
    }
}
