import {
    createModuleObjectLabeler,
    createModuleObjectId,
    ensureLabelIsUnique,
    ProjectObject,
    AccessLevels_Unblocked,
    createAnonymousCtrl,
    isApiExtending,
    Maps,
    ObjectApi,
    ObjectImplementation,
    ObjectOutlet,
    OUTLET_PARENT,
    OutletConfig,
    schema,
    SUBOBJECT_DELIMITER,
} from '@grenton/gm-common';
import { ProjectFirmwareImpl, ProjectDeviceModuleInstanceImpl, ProjectObjectImpl } from '../model';
import { importObject } from './importObject';
import { newObjectId } from '../id';
import { createComponentInstanceImpl } from './createComponentInstanceImpl';

/**
 * TODO merge with createObjectsImplFromNonModuleComponent (see description there)
 * @param firmware
 * @param component
 * @param hasLabel
 * @param tags
 * @returns
 */
export function createObjectsFromModuleComponent(
    firmware: ProjectFirmwareImpl,
    componentRef: string,
    hasLabel: (label: string) => boolean,
    tags?: string[],
): ComponentEntry {
    const componentSpec = firmware.getComponent(componentRef);
    if (!componentSpec) throw new Error(`firmware does not define component "${componentRef}"`);

    if (componentSpec.spec.type !== 'module') {
        throw new Error(`component ${componentRef} is not a module`);
    }

    const providedObjects = componentSpec.spec.provides.objects;
    if (!providedObjects?.length) {
        throw new Error(`component ${componentRef} does not define objects`);
    }

    const component = createComponentInstanceImpl(componentRef, componentSpec.spec.type);
    const createdObjects: ObjectEntry[] = [];
    const objectLabeler = createModuleObjectLabeler({
        providedObjectsLength: componentSpec.spec.provides.objects.length,
        module: { prefix: componentSpec.spec.prefix, name: componentSpec.spec.name, id: component.uuid },
    });

    function asObjectEntry(plain: ProjectObjectWithDependents): ObjectEntry {
        return new ObjectEntryImpl(
            importObject(firmware, plain.object),
            plain.companions.map((obj) => asObjectEntry(obj)),
        );
    }

    providedObjects.forEach((multiObjectEntry) => {
        const objectApi = firmware.resolveApiRef(multiObjectEntry.protocol);
        if (!objectApi) {
            throw new Error(`module ${componentSpec.id} references unknown api "${multiObjectEntry.protocol}"`);
        }
        for (let i = 1; i <= multiObjectEntry.count; i++) {
            // port identifies this object inside the module.
            // we need a simple && consistent strategy across GM and hardware
            const cmpObjectId = createModuleObjectId(multiObjectEntry, i);
            const label = ensureLabelIsUnique(objectLabeler({ multiObjectEntry, index: i }), hasLabel);

            const created = createModuleObject({
                parentId: null,
                label,
                api: objectApi,
                impl: {
                    type: 'module',
                    componentRef: {
                        componentId: component.uuid,
                        objectId: cmpObjectId,
                    },
                },
                deviceType: multiObjectEntry.deviceTypes?.length === 1 ? multiObjectEntry.deviceTypes[0] : undefined,
                tags,
            });
            createdObjects.push(asObjectEntry(created));
        }
    });
    return new ComponentEntryImpl(component, createdObjects);
}

function createModuleObject(
    opts:
        | { parentId: null; label: string; api: ObjectApi; impl: ObjectImplementation; deviceType?: string; tags?: string[] }
        | { parentId: string; suffix: string; label: string; api: ObjectApi; impl: ObjectImplementation; tags?: string[] },
): ProjectObjectWithDependents {
    // add parent reference only when 1) there is a parent 2) child defines PARENT outlet
    const isChild = opts.parentId !== null && Boolean(opts.api.flat.outlets[OUTLET_PARENT]);

    // label of child is derived from outlet name + index
    const id = isChild ? opts.parentId + SUBOBJECT_DELIMITER + opts.suffix : newObjectId();

    const outletConfigs: OutletConfig[] = isChild ? [{ id: OUTLET_PARENT, refs: { type: 'static', value: { ids: [opts.parentId] } } }] : [];

    const objectApi: schema.ProtocolPtrRef = { type: 'ref', ref: opts.api.id };
    const object: ProjectObject = {
        id: id,
        label: opts.label,
        tags: opts.tags,
        init: {
            features: Maps.values(opts.api.flat.features)?.map((f) => ({
                id: f.id,
                value: f.default || null,
            })),
            outlets: outletConfigs,
        },
        api: objectApi,
        impl: opts.impl,
        deviceType: opts.parentId === null ? opts.deviceType : undefined,
        accessLevel: AccessLevels_Unblocked, //TODO remove
    };

    const result: ProjectObjectWithDependents = {
        object,
        companions: [],
    };

    /**
     * to treat outlet as a candidate for filling with subobjects, it has to be:
     * 1. bidirectional with specified cardinality, because we need to know how many children to create
     * 2. child API must define a parent outlet with API compatible with parent object. We want to create bidirectional parent-child relation
     *    w/o this condition, it would be possible for child to have many parents, which makes little sense.
     */
    const isOutletSuitableForSubobjects = (o: ObjectOutlet) =>
        o.bidirectional && o.api.flat.outlets[OUTLET_PARENT] && isApiExtending(o.api.flat.outlets[OUTLET_PARENT]?.api, objectApi.ref);

    result.companions.push({ object: createAnonymousCtrl(object), companions: [] });

    // for non scriptable objects, create child objects for all readonly and singleton outlets
    const subobjects: { outletId: string; children: ProjectObjectWithDependents[] }[] = Maps.values(opts.api.flat.outlets)
        .filter((o) => o.id !== OUTLET_PARENT)
        // treat all readonly and singleton as child objects. we may drop singleton requirement at some point
        .filter(isOutletSuitableForSubobjects)
        .map((o) => {
            const baseName = o.label || o.id;
            const children: ProjectObjectWithDependents[] = [];
            const count = o.bidirectional || 0;
            const singleton = (o.bidirectional ?? o.maxItems) === 1;
            // we start from 1 (it makes more sense on UI and coincides with lua arrays)
            for (let subObjectIndex = 1; subObjectIndex <= count; subObjectIndex++) {
                const subObjectName = singleton ? baseName : `${baseName}[${subObjectIndex}]`;
                const subObjectSuffix = singleton ? baseName : `${baseName}_${subObjectIndex}`;
                const componentRefName = singleton ? o.id : `${o.id}[${subObjectIndex}]`;

                children.push(
                    createModuleObject({
                        parentId: object.id,
                        suffix: subObjectSuffix,
                        label: subObjectName,
                        api: o.api,
                        impl:
                            opts.impl?.type === 'module' && opts.impl.componentRef
                                ? {
                                      ...opts.impl,
                                      componentRef: { ...opts.impl.componentRef, objectId: `${opts.impl.componentRef.objectId}.${componentRefName}` },
                                  }
                                : opts.impl,
                        tags: opts.tags,
                    }),
                );
            }
            return {
                outletId: o.id,
                children,
            };
        });

    for (const subobj of subobjects) {
        let outletInit = object.init!.outlets!.find((o) => o.id === subobj.outletId);
        if (!outletInit) {
            outletInit = {
                id: subobj.outletId,
                refs: {
                    type: 'static',
                    value: { ids: [] },
                },
            };
            object.init!.outlets!.push(outletInit);
        }
        outletInit.refs.value.ids!.push(...subobj.children.map((obj) => obj.object.id));
        result.companions.push(...subobj.children);
    }
    return result;
}

/*
 * component A may have 2 objects, A1, A2
 * each of these objects may have subobjects, e.g. A1.1, A1.2, A2.1, A2.2
 * each of top and subobjects, may have anonymous controller: A1@ctrl, A2@ctrl, A1.1@ctrl, A1.2@ctrl, A2.1@ctrl, A2.2@ctrl
 *
 * each of top or subobjects may have defined outlet filler, and by such, cause creation additional components:
 * A1->B1, A2->B2, A1.1->C1, A1.2->D1, A2.1->C2, A2.2->D2
 *
 * we'd like to track which object caused creating which components or objects
 *
 * {A;[A1,A2]}
 *      [A1-> {A;A1@ctrl}, A1->{A;A1.1}, A1->{A;A1.2}]
 *                                [A1.1->{A; A1.1@ctrl}]
 *                                             [A1.2->{A,A1.2@ctrl}]
 *      [A1-> B]
 *
 * B-> {B;B1,B2}
 *
 * ergo, each objects may cause adding additional objects belonging to the same component, but being its children
 * and/or any number of components that have their own tree of objects
 *
 * ComponentRecord = {component, ObjectRecord[]}
 * ObjectRecord= {children:ObjectRecord[], components: ComponentRecord}
 *
 *
 *
 */

export type ComponentEntry = {
    component: ProjectDeviceModuleInstanceImpl;
    objects: ObjectEntry[];
};

/**
 * adding a single object may cause adding its subobjects/anonymous controllers (all belonging to the same component as "object")
 * or completely new components that are soft-linked to this object.
 */
export type ObjectEntry = {
    object: ProjectObjectImpl;
    // these are objects that are created as a result of adding this object, but still linked to the same component
    // e.g. anonymous controllers, subobjects
    createdObjects: ObjectEntry[];

    // these are components that are created as a result of adding this object, with its own tree of objects
    // these are specified in autoCreate hook of root component
    createdComponents: ComponentEntry[];
};

export class ComponentEntryImpl implements ComponentEntry {
    constructor(
        readonly component: ProjectDeviceModuleInstanceImpl,
        public objects: ObjectEntry[] = [],
    ) {}
    addObject(object: ObjectEntry) {
        this.objects.push(object);
        return this;
    }
}
export class ObjectEntryImpl implements ObjectEntry {
    constructor(
        readonly object: ProjectObjectImpl,
        public createdObjects: ObjectEntry[] = [],
        public createdComponents: ComponentEntry[] = [],
    ) {}
    addCreatedObject(object: ObjectEntry) {
        this.createdObjects.push(object);
        return this;
    }
    addCreatedComponent(component: ComponentEntry) {
        this.createdComponents.push(component);
        return this;
    }
}

// internal type, it gets converted into *Impl
type ProjectObjectWithDependents = {
    object: ProjectObject;
    companions: ProjectObjectWithDependents[];
};
