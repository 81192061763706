import {
    ObjectApi,
    ObjectImplementation
} from "@grenton/gm-common";
import {ProjectObjectImpl, ProjectFirmwareImpl} from "@grenton/gm-logic";
import { GIconName, icons } from "@grenton/gm/ui/icons/icons";

export type ObjectIconResolver = (object:ProjectObjectImpl)=>GIconName|null

export function objectIconResolver(firmware: ProjectFirmwareImpl) : ObjectIconResolver {
    const functionalTypeIcon = functionalTypeIconResolver(firmware)
    return (object:ProjectObjectImpl) => objectIcon(object, functionalTypeIcon)
}

function objectIcon(target: ProjectObjectImpl, getFunctionalTypeIcon:(id:string)=>GIconName|null) : GIconName|null {
    return (target.userType ? getFunctionalTypeIcon(target.userType) : null) || 
        implIcon(target.impl) || 
        apiIcon(target.api.api)
}

function implIcon(impl: Pick<ObjectImplementation, "type">) : GIconName|null {
    switch (impl.type) {
        case "script" :
            return "logic"
        default:
            return null
    }
}

export function apiIcon(api: ObjectApi) : GIconName|null {
    const iconName = api?.meta?.icon as GIconName
    const isIconDefined = Boolean(icons[iconName])
    if (isIconDefined) {
        return iconName
    } else {
        for (const ext of api.extending || []) {
            const iconName = apiIcon(ext)
            if (iconName) return iconName
        }
    }
    return "unknown"
}

export function functionalTypeIconResolver(firmware:ProjectFirmwareImpl) : (functionalType:string)=>GIconName|null {
    return (functionalType:string) => {
        const iconName = firmware.getFunctionalType(functionalType)?.meta?.icon as GIconName
        return (iconName && icons[iconName]) ? iconName : null
    }
}