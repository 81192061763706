import { Components, Theme } from '@mui/material';

export const MuiDialogContentText: Components<Omit<Theme, 'components'>>['MuiDialogContentText'] = {
    defaultProps: {
        variant: 'm',
    },
    styleOverrides: {
        //@ts-ignore
        root: ({ _ownerState, _theme }) => ({}),
    },
};
