import { ProjectImpl, StateUpdater, Command, CommandHandler } from '@grenton/gm-logic';
import { I18nLang } from '@grenton/gm-common';

export class I18nLangAddCommand implements Command {
    readonly type = 'i18nLangAddCommand';

    constructor(readonly lang: I18nLang) {}
}

export class I18nLangAddCommandHandler implements CommandHandler<I18nLangAddCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd instanceof I18nLangAddCommand;
    }

    execute(cmd: { lang: I18nLang }): void {
        this.update((p) => p.withI18nLang(cmd.lang));
    }
}
