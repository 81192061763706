import { CommandHandler, Command } from '../../dispatcher';
import { ImportProjectImpl } from '../builders';
import { LoadNewProject } from '../loadNewProject';
import { LoadProjectFromJsonCommand } from './load-project-from-json-command';

export class LoadProjectFromJsonCommandHandler implements CommandHandler<LoadProjectFromJsonCommand> {
    constructor(
        private projectLoader: LoadNewProject,
        private importProjectImpl: ImportProjectImpl,
    ) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'LoadProjectFromJsonCommand';
    }

    execute(cmd: LoadProjectFromJsonCommand): void {
        this.projectLoader(this.importProjectImpl(JSON.parse(cmd.json)));
    }
}
