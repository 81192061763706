import {Box, ButtonBase, Divider, IconButton, Stack, Typography} from "@mui/material";
import {KeyMap, Maps, TagsCategory} from "@grenton/gm-common";
import {TagSelection} from "./utils";
import {TagLabel,icons} from "@grenton/design-system";

export type TagSelectorProps = {
    categories: TagsCategory[]; // all tags to display
    selected: KeyMap; // selected tags
    forceMultiple?: boolean; // treat all categories as multi-selected (used by OutletProps)
    onChange: (selected: KeyMap) => void;
    onAdd?: (baseTag?:string) => void;
    onCreateCategory?: () => void;
};

export function TagSelector({ categories, selected, forceMultiple, onChange, onAdd, onCreateCategory }: TagSelectorProps) {
    const selection = TagSelection.from(categories, Maps.reduceKeyMap(selected), Boolean(forceMultiple));

    return (
        <Stack direction="row" alignItems="start">
            {selection.categories.map(categorySelection => {
                return <Stack direction="column" key={categorySelection.category.name}>
                    <Typography variant="l" sx={{pl:1,mt:0.2}}>{categorySelection.category.name}</Typography>
                    <Divider/>
                    <Stack sx={{gap:1,mt:2,mr:2, flexDirection:'column',alignItems:'start'}}>
                    {
                        categorySelection.category.values.map(tag => {
                            const fullTag = `${categorySelection.category.name}:${tag}`
                            const isTagSelected = Boolean(selected[fullTag])
                            return (
                                <ButtonBase component="span"
                                            sx={{
                                                borderRadius: '1em'
                                            }}
                                            key={tag}
                                            onClick={() => onChange(Maps.asKeyMap(selection.toggle(fullTag).selected))}>
                                    <TagLabel
                                        label={tag}
                                        color={isTagSelected ? categorySelection.category.color : undefined}
                                    />
                                </ButtonBase>
                            )
                        })
                    }
                    {onAdd && <IconButton
                            onClick={()=>onAdd(categorySelection.category.name)}
                            size="small">
                            <icons.Add/>
                        </IconButton>}
                     
                    </Stack>
                </Stack>
            })}
            {onCreateCategory ? (
                    <Box
                        flexGrow={2}
                        display={"flex"}
                        justifyContent="end"
                    >
                        <IconButton
                            onClick={onCreateCategory}
                            size="small">
                            <icons.Add/>
                        </IconButton>
                    </Box>
                ) :
                null
            }
        </Stack>
    )

}
