import { Block, Blocks, FieldLabel, MenuOption, inputs } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { schema } from '@grenton/gm-common';
import { BlockContext } from '@grenton/gm-logic';
import {
    BlockDelegate,
    BlockWithDelegate,
    createDelegate,
    emptyOption,
    FieldDropdownModel,
    STATEMENT_BLOCK_STYLE,
    safeDisconnect,
    NOT_SELECTED,
    lua_invokeObjectMethod,
} from './common';
import { FieldDropdownEx } from './field-dropdown-ex';

export namespace ApiMethodInvoke {
    export const Type = 'api-method-invoke';

    export const ENTITY_FIELD = 'ENTITY';
    export const METHOD_FIELD = 'METHOD';
    const EMPTY_OPTION = emptyOption('{method}');

    export interface ExtraState {
        method?: {
            args: number;
            output: boolean;
        };
    }

    export class Delegate implements BlockDelegate<ExtraState> {
        state: ExtraState = {};

        method?: schema.Method;

        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, METHOD_FIELD, [EMPTY_OPTION]);

            block.appendValueInput(ENTITY_FIELD);

            block.appendDummyInput().appendField(new FieldLabel(':')).appendField(new FieldDropdownEx(this.dropdownModel.generator), METHOD_FIELD);

            // enable all connections, adjust later
            this.updateBlockType();

            block.setInputsInline(true);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        updateBlockType() {
            const output = Boolean(this.state.method?.output);
            this.block.setOutput(output, null);
            this.block.setPreviousStatement(!output, null);
            this.block.setNextStatement(!output, null);
        }

        connectToEntity(block: Block, methodId?: string) {
            const input = this.block.getInput(ENTITY_FIELD)!;
            if (block.outputConnection) {
                input.connection!.connect(block.outputConnection);
            }
            this.block.setFieldValue(methodId || '', METHOD_FIELD);
        }

        onUpdate(context: BlockContext) {
            const entity = context.getInputType(ENTITY_FIELD);

            const methods = entity?.api.flat.methods || {};
            const options: MenuOption[] = Object.entries(methods)
                .filter((m) => !m[1].private)
                .map((m) => [m[1].label || m[1].id, m[0]]);
            options.unshift(EMPTY_OPTION);

            let methodId = this.block.getFieldValue(METHOD_FIELD);
            let selected = options.find((o) => o[1] === methodId) || EMPTY_OPTION;

            this.dropdownModel.setOptions(options, selected[1]);

            this.method = (Object.entries(methods).find((m) => m[0] === selected[1]) || [])[1];

            const params = this.method?.params || [];
            const output = Boolean(this.method?.result && this.method.result.type !== schema.PROPERTY_TYPE_VOID);

            this.state.method = {
                args: params.length,
                output,
            };

            if (output) {
                safeDisconnect(this.block.previousConnection);
                safeDisconnect(this.block.nextConnection);
            } else {
                safeDisconnect(this.block.outputConnection);
            }
            this.updateBlockType();
            this.ensureArgsInputs(params.length, params);
        }

        saveState() {
            return this.state;
        }

        /*
         * this method is called after init() and also when 'marker' block is created
         * if block has dynamic number of inputs, their current list must be stored in extraState
         * and then recreated here.
         * otherwise 'marker' block will fail to initialize, because it can try to copy all input values
         * from the source block to a marker block.
         */
        loadState(state: ExtraState) {
            this.state = state;
            this.updateBlockType();
            this.ensureArgsInputs(state.method?.args || 0);
        }

        ensureArgsInputs(count: number, params?: schema.MethodParam[]) {
            let i = 0;
            for (; i < count; i++) {
                this.addArgumentInput(i, params ? params[i] : undefined);
            }
            this.removeAllArgumentInputs(i);
            this.block.setInputsInline(count <= 2);
        }

        addArgumentInput(index: number, param?: schema.MethodParam) {
            const inputName = `ARG_${index}`;
            const labelName = `${inputName}_NAME`;
            let input = this.block.getInput(inputName);
            if (!input) {
                input = this.block
                    .appendValueInput(inputName)
                    .setAlign(inputs.Align.RIGHT)
                    .appendField(new FieldLabel(param?.id), labelName)
                    .appendField(':');
            } else {
                this.block.setFieldValue(param?.id, labelName);
            }
            // .setCheck(convertToBlocklyType(param))   // this can be done!
        }

        removeAllArgumentInputs(from: number) {
            let index = from;
            while (index < this.block.inputList.length) {
                const inputName = `ARG_${index}`;
                const input = this.block.getInput(inputName);
                if (input) {
                    safeDisconnect(input.connection);
                    this.block.removeInput(inputName, true);
                }
                index++;
            }
        }
    }
}

Blocks[ApiMethodInvoke.Type] = createDelegate((block) => new ApiMethodInvoke.Delegate(block));

luaGenerator.forBlock[ApiMethodInvoke.Type] = (block, generator) => {
    const _block = block as BlockWithDelegate<ApiMethodInvoke.Delegate>;
    const entityRef = generator.valueToCode(block, ApiMethodInvoke.ENTITY_FIELD, Order.ATOMIC) || NOT_SELECTED;
    const method = _block.delegate.method;

    if (!method) {
        return `${entityRef}.${NOT_SELECTED}()`;
    }

    const methodReturnsValue = Boolean(method?.result && method.result.type !== schema.PROPERTY_TYPE_VOID);

    // const params = (method?.params||[])
    //     .map((param, index)=>([param.id, BlocklyLua.valueToCode(block, `ARG_${index}`, BlocklyLua.ORDER_ATOMIC)]))
    //     .filter(p=>!!p[1]).map(p=>(`${p[0]}=${p[1]}`))

    // const params_str = params.length ? `{${params.join(`,\n`)}}` : ''
    // const methodName = method?.label||method?.id||NOT_SELECTED
    // const code = `${entityRef}.${methodName}(${params_str})\n`

    const code = lua_invokeObjectMethod(entityRef, method, (_, index) => generator.valueToCode(block, `ARG_${index}`, Order.ATOMIC));
    return methodReturnsValue ? [code, Order.ATOMIC] : code;
};
