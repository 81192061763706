import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import { compare } from 'semver';
import { type ObservableValue } from '../rx';
import { type ComponentSpec } from '../model/logic';
import { type DeviceTypeMeta, type FQKey } from '../model';
import { type ProtocolSpec } from '../model/objects/api';
import { DataPoller } from '../data-poller';

type FQKeyTree<T> = Record<string, Record<string, Record<string, T>>>;

export type ProtocolRepoItem = FQKey & { spec: ProtocolSpec };

export type DeviceTypesItem = {
    version: string;
    types: Record<
        string,
        {
            meta?: DeviceTypeMeta;
            protocols: string[];
        }
    >;
};

export type FirmwareRepoItem = FQKey & {
    components: Record<string, string>;
    packages: Record<string, string>;
    meta: { 'device-types': string };
};

type RepoData = {
    firmwares: FQKeyTree<FirmwareRepoItem>;
    components: FQKeyTree<ComponentSpec>;
    protocols: FQKeyTree<ProtocolRepoItem>;
    meta: {
        'device-types': Record<string, DeviceTypesItem>;
    };
};

function traverseFQKeyTree<T>(tree: FQKeyTree<T>): T[] {
    const result: T[] = [];
    for (const org of Object.values(tree)) {
        for (const name of Object.values(org)) {
            for (const version of Object.values(name)) {
                result.push(version);
            }
        }
    }
    return result;
}

export interface RepoClient {
    get protocols(): ObservableValue<ProtocolRepoItem[]>;

    get firmwares(): ObservableValue<FirmwareRepoItem[]>;

    get components(): ObservableValue<ComponentSpec[]>;

    get deviceTypes(): ObservableValue<DeviceTypesItem[]>;
}

export class RepoClientImpl implements RepoClient {
    private readonly _firmwares = new BehaviorSubject<FirmwareRepoItem[]>([]);
    private readonly _protocols = new BehaviorSubject<ProtocolRepoItem[]>([]);
    private readonly _components = new BehaviorSubject<ComponentSpec[]>([]);
    private readonly _deviceTypes = new BehaviorSubject<DeviceTypesItem[]>([]);

    readonly loaded = new BehaviorSubject<boolean>(false);

    private poller: DataPoller<RepoData>;

    constructor(private readonly repoUrl: string) {
        this.poller = new DataPoller<RepoData>({
            url: `${this.repoUrl}/_/index.json`,
            intervalMs: 10 * 1000,
            afterSuccessfulPollIntervalMs: 60 * 1000,
            timeoutMs: 30 * 1000,
            logLevel: log.levels.DEBUG,
        });
        this.poller.data.subscribe((data) => {
            if (data) {
                this._firmwares.next(
                    (function sortAndPrepareFirmwareData() {
                        // Named function
                        const firmwares = traverseFQKeyTree(data?.firmwares ?? {});
                        firmwares.sort((a, b) => -compare(a.version, b.version));
                        return firmwares;
                    })(),
                );
                this._protocols.next(traverseFQKeyTree(data?.protocols ?? {}));
                this._components.next(traverseFQKeyTree(data?.components ?? {}));
                this._deviceTypes.next(Object.values(data?.meta['device-types'] ?? {}));
                this.loaded.next(true);
            }
        });
    }

    async startPolling() {
        await this.poller.start();
    }

    get protocols(): ObservableValue<ProtocolRepoItem[]> {
        return this._protocols;
    }

    get firmwares(): ObservableValue<FirmwareRepoItem[]> {
        return this._firmwares;
    }

    get components(): ObservableValue<ComponentSpec[]> {
        return this._components;
    }

    get deviceTypes(): ObservableValue<DeviceTypesItem[]> {
        return this._deviceTypes;
    }
}
