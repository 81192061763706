import { ScriptFormat } from '@grenton/gm-common';
import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { ProjectImpl, ScriptImpl } from '../model';

export class SwitchEditModeCommand implements Command {
    readonly type = 'SwitchEditModeCommand';
    constructor(
        readonly data: {
            mode: ScriptFormat;
            oid: string;
            path: string;
        },
    ) {}
}

export class SwitchEditModeCommandHandler implements CommandHandler<SwitchEditModeCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'SwitchEditModeCommand';
    }

    /*
     * TODO here we must generate missing formats
     */
    execute(cmd: SwitchEditModeCommand): void {
        this.update((p) => {
            const entity = p.getObjectById(cmd.data.oid);
            if (entity) {
                return p.withObject(
                    entity.withScripts(
                        entity.scripts.withScriptModifier(cmd.data.path, (script: ScriptImpl) => {
                            let s = script.withEditMode(cmd.data.mode);
                            if (cmd.data.mode === 'lua') s = s.withActions(null).withVisual(null);
                            if (cmd.data.mode === 'visual') s = s.withActions(null);
                            return s;
                        }),
                    ),
                );
            } else {
                return p;
            }
        });
    }
}
