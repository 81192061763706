import { Blocks, Block, FieldLabel, MenuOption } from 'blockly';
import { schema } from '@grenton/gm-common';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, BlockWithDelegate, createDelegate, emptyOption, FieldDropdownModel, NOT_SELECTED, STATEMENT_BLOCK_STYLE } from './common';
import { FieldDropdownEx } from './field-dropdown-ex';
import { luaGenerator, Order } from 'blockly/lua';

export namespace ApiFeatureSet {
    export const Type = 'api-feature-set';

    export const ENTITY_FIELD = 'ENTITY';
    export const FEATURE_FIELD = 'FEATURE';
    export const FEATURE_VALUE = 'VALUE';
    const EMPTY_OPTION = emptyOption('{feature}');

    export class Delegate implements BlockDelegate {
        feature?: schema.Feature;

        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, FEATURE_FIELD, [EMPTY_OPTION]);

            block.appendValueInput(ENTITY_FIELD).appendField(new FieldLabel('set'));

            block
                .appendDummyInput()
                .appendField(new FieldLabel('.'))
                .appendField(new FieldDropdownEx(this.dropdownModel.generator), FEATURE_FIELD)
                .appendField(new FieldLabel('to'));

            block.appendValueInput(FEATURE_VALUE);

            this.updateBlockType(false);

            block.setInputsInline(true);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        updateBlockType(output: boolean) {
            this.block.setOutput(output, null);
            this.block.setPreviousStatement(!output, null);
            this.block.setNextStatement(!output, null);
        }

        connectToEntity(block: Block, featureId?: string) {
            const input = this.block.getInput(ENTITY_FIELD)!;
            if (block.outputConnection) {
                input.connection!.connect(block.outputConnection);
            }
            this.block.setFieldValue(featureId || '', FEATURE_FIELD);
        }

        onUpdate(context: BlockContext) {
            const entity = context.getInputType(ENTITY_FIELD);
            const methods = entity?.api.flat.features || {};
            const options: MenuOption[] = Object.entries(methods).map((method) => [method[1].label || method[1].id, method[0]]);
            options.unshift(EMPTY_OPTION);

            let methodId = this.block.getFieldValue(FEATURE_FIELD);
            let selected = options.find((option) => option[1] === methodId) || EMPTY_OPTION;

            this.dropdownModel.setOptions(options, selected[1]);

            this.feature = (Object.entries(methods).find((m) => m[0] === selected[1]) || [])[1];
        }
    }
}

Blocks[ApiFeatureSet.Type] = createDelegate((block) => new ApiFeatureSet.Delegate(block));

luaGenerator.forBlock[ApiFeatureSet.Type] = (block, generator) => {
    const _block = block as BlockWithDelegate<ApiFeatureSet.Delegate>;
    const entityRef = generator.valueToCode(block, ApiFeatureSet.ENTITY_FIELD, Order.ATOMIC) || NOT_SELECTED;
    const value = generator.valueToCode(block, ApiFeatureSet.FEATURE_VALUE, Order.ATOMIC);
    const feature = _block.delegate.feature;
    const code = `${entityRef}.${feature?.label || feature?.id || NOT_SELECTED} = ${value}\n`;
    return code;
};
