import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { NetworkView } from './networkView';

export class SelectNetworkCommand implements Command {
    readonly type = 'SelectNetworkCommand';
    constructor(readonly networkId: string) {}
}

export class SelectNetworkLookupHandler implements CommandHandler<SelectNetworkCommand> {
    constructor(private updateNetworkView: StateUpdater<NetworkView>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'SelectNetworkCommand';
    }

    execute(cmd: SelectNetworkCommand): void | Promise<void> {
        this.updateNetworkView((value) => ({ ...value, selectedNetworkId: cmd.networkId }));
    }
}
