import { Lists, IdMap, Maps, TagsCategory } from '@grenton/gm-common';
import { SelectedTagsImpl, TAG_SEPARATOR } from '@grenton/gm-logic';

/**
 * used only to handle selection changes
 */

export class CategoryTagSelection {
    constructor(
        readonly category: TagsCategory,
        readonly selected: SelectedTagsImpl,
    ) {}

    toggle(tag: string) {
        return new CategoryTagSelection(this.category, this.selected.toggle(tag));
    }
}

export class TagSelection {
    static from(categories: TagsCategory[], selected: string[], forceMultiple: boolean) {
        return new TagSelection(
            Lists.reduce(categories, (category) => {
                const prefix = `${category.name}${TAG_SEPARATOR}`;
                const tags = selected.filter((tag) => tag.startsWith(prefix)).map((tag) => tag.substring(prefix.length));
                return [category.name, new CategoryTagSelection(category, SelectedTagsImpl.from(tags, category.multiple || forceMultiple))];
            }),
        );
    }

    constructor(readonly _categories: IdMap<CategoryTagSelection>) {}

    public toggle(tag: string) {
        const tokens = tag.split(TAG_SEPARATOR);
        if (tokens.length < 2) return this;
        const categoryName = tokens[0]!;
        const category = this._categories[categoryName];
        if (!category) return this;

        return new TagSelection({ ...this._categories, [categoryName]: category.toggle(tokens[1]!) });
    }

    get categories() {
        return Maps.values(this._categories);
    }

    get selected(): string[] {
        const result: string[] = [];
        this.categories.forEach((c) => {
            result.push(...c.selected.selected.map((t) => `${c.category.name}${TAG_SEPARATOR}${t}`));
        });
        return result;
    }
}
