import { Command, CommandHandler } from '../../dispatcher';
import { ProjectImpl } from '../../project';
import { StateUpdater } from '../../utils/state';

export class LinkProjectModuleToHardwareCommand implements Command {
    readonly type = 'LinkProjectModuleToHardware';
    constructor(readonly data: { mapping: { [moduleId: string]: string } }) {}
}

export class LinkProjectModuleToHardwareHandler implements CommandHandler<LinkProjectModuleToHardwareCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command) {
        return cmd.type === 'LinkProjectModuleToHardware';
    }

    execute(cmd: LinkProjectModuleToHardwareCommand) {
        this.update((p) => {
            return p.withHardware(p.hardware.withMapping(cmd.data.mapping));
        });
    }
}
