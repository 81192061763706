import { ActionTemplate } from '../../action-template';
import { ActionApiWidget } from './widget';
import { ActionApiGenerator } from './generator';
import { ActionApiModel } from './model';
import { ObjectApi } from '@grenton/gm-common';

export const ActionApiTemplate: ActionTemplate<ActionApiModel> = {
    type: 'api',
    label: 'API',
    order: 100000,
    defaults: { callables: {} },
    widget: ActionApiWidget,
    generator: ActionApiGenerator,

    isApplicable(_api: ObjectApi) {
        return true;
    },
};
