import { Command, CommandHandler } from '../../dispatcher';
import { ProjectImpl } from '../../project';
import { StateUpdater } from '../../utils/state';

export class RemoveProjectModuleLinkToHardwareCommand implements Command {
    readonly type = 'RemoveProjectModuleLinkToHardwareCommand';
    constructor(readonly data: { moduleId: string }) {}
}

export class RemoveProjectModuleLinkToHardwareCommandHandler implements CommandHandler<RemoveProjectModuleLinkToHardwareCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command) {
        return cmd.type === 'RemoveProjectModuleLinkToHardwareCommand';
    }

    execute(cmd: RemoveProjectModuleLinkToHardwareCommand) {
        this.update((p) => p.withHardware(p.hardware.withoutMapping(cmd.data.moduleId)));
    }
}
