import { Command, CommandHandler, ObjectScriptRef } from '@grenton/gm-logic';

export class EditScriptCommand implements Command {
    readonly type = 'EditScriptCommand';
    constructor(readonly data: ObjectScriptRef) {}
}

export type ObjectScriptEditor = {
    setObjectScriptToEdit: (script: ObjectScriptRef) => void;
};

export class EditScriptCommandHandler implements CommandHandler<EditScriptCommand> {
    constructor(private editor: (ref: ObjectScriptRef) => void) {}

    public supports(cmd: EditScriptCommand) {
        return cmd.type === 'EditScriptCommand';
    }

    public execute(cmd: EditScriptCommand) {
        this.editor(cmd.data);
    }
}
