export type ProjectSecurity = {
    users: ProjectUser[];
    roles: ProjectRole[];
};

export type ProjectUser = {
    name: string;
    pwd: string; // hash
    disabled: boolean;
    accessLevel: string; // TODO tmp
    roles: string[];
};

export type ProjectUserCredentials = {
    username: string;
    password: string;
};

export type ProjectRole = {
    rolename: string;
    accessLevel: ProjectAccessLevel;
};

export type ProjectAccessLevel = ProjectObjectPermissions[];

export type ProjectObjectPermissions = {
    object: string;
    permission: ProjectObjectPermissionType[];
};

export type ProjectObjectPermissionType = 'read' | 'write';

export const ROLE_ADMIN: ProjectRole = {
    rolename: 'admin',
    accessLevel: [
        {
            object: '*',
            permission: ['read', 'write'],
        },
    ],
};
