import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { ProjectImpl } from '../model';

export interface EditTagForm {
    category: string;
    value: string;
    single?: boolean;
}

export class AddTagCommand implements Command {
    readonly type = 'AddTagCommand';
    constructor(readonly data: { form: EditTagForm }) {}
}

export class AddTagCommandHandler implements CommandHandler<AddTagCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'AddTagCommand';
    }

    execute(cmd: AddTagCommand): void {
        this.update((project) => project.withTag(`${cmd.data.form.category}:${cmd.data.form.value}`));
    }
}
