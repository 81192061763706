import {
    ProjectObject,
    IdMap,
    ObjectApi,
    ProjectObjectInit,
    Lists,
    OUTLET_PARENT,
    Script,
    ObjectID,
    isInlinedApi,
    ObjectImplementation,
    ANONYMOUS_CONTROLLER_SUFFIX,
    ModuleObjectRef,
} from '@grenton/gm-common';
import { SelectedTagsImpl } from './selected-tags';
import { FeatureConfigImpl } from './object-feature';
import { OutletConfigImpl, EMPTY_SELECTOR } from './object-outlet';
import { schema } from '@grenton/gm-common';
import { ScriptImpl } from './script';
import { ProjectObjectApi } from './object-api';

export function isAnonymousController(objectId: ObjectID) {
    return objectId.endsWith(ANONYMOUS_CONTROLLER_SUFFIX);
}

export function isAnonymousControllerOf(ctrlId: ObjectID, objectId: ObjectID) {
    return ctrlId === `${objectId}${ANONYMOUS_CONTROLLER_SUFFIX}`;
}

export function getAnonymousControllerObject(objectId: ObjectID) {
    if (!isAnonymousController(objectId)) throw new Error(`not anonymous controller (${objectId})`);
    return objectId.substring(0, objectId.length - ANONYMOUS_CONTROLLER_SUFFIX.length);
}

/*
 * what are we going to do with labels?
 * we need either fully qualified label: "simple-panel-1.button-1"
 * or simple label (for simple modules): "lamp-1"
 *
 * when adding a module to the project we can control uniqueness of its label.
 * when adding changing an object's label inside the module, we can easily control uniqueness of its label within
 * module namespace.
 * therefore, we cannot allow to have empty module labels - we'd have more than one module with the same (empty) label.
 * but we can have an object with an empty label w/o violating uniqueness constraint.
 */

export class ProjectObjectInitImpl {
    static from(init: ProjectObjectInit) {
        return new ProjectObjectInitImpl({
            features: Lists.reduce(init.features, (f) => [f.id, new FeatureConfigImpl(f.id, f.value)]),
            outlets: Lists.reduce(init.outlets, (o) => [o.id, OutletConfigImpl.from(o)]),
        });
    }

    constructor(
        private data: {
            features: IdMap<FeatureConfigImpl>;
            outlets: IdMap<OutletConfigImpl>;
        },
    ) {}
    get features() {
        return this.data.features;
    }
    get outlets() {
        return this.data.outlets;
    }

    requireOutlet(id: string) {
        return this.outlets[id] || new OutletConfigImpl({ id, type: 'dynamic', staticRefs: [], dynamicRefs: EMPTY_SELECTOR });
    }

    withOutlet(outlet: OutletConfigImpl) {
        return new ProjectObjectInitImpl({ ...this.data, outlets: { ...this.data.outlets, [outlet.id]: outlet } });
    }

    withOutletModifier(id: string, fn: (outlet: OutletConfigImpl) => OutletConfigImpl) {
        const outlet = this.data.outlets[id] || OutletConfigImpl.from({ id, refs: { type: 'dynamic', value: {} } });
        return this.withOutlet(fn(outlet));
    }

    // TODOX mixing editable and non-editable values in a single field is concerning!
    // we can store staticRefs and override it here or keep them separately
    withOutlets(outlets: IdMap<OutletConfigImpl>) {
        return new ProjectObjectInitImpl({ ...this.data, outlets });
    }

    withoutOutlet(id: string): ProjectObjectInitImpl {
        const outlets = { ...this.data.outlets };
        delete outlets[id];
        return new ProjectObjectInitImpl({ ...this.data, outlets });
    }

    withFeature(feature: FeatureConfigImpl) {
        return new ProjectObjectInitImpl({ ...this.data, features: { ...this.data.features, [feature.id]: feature } });
    }

    withFeatures(features: IdMap<FeatureConfigImpl>) {
        return new ProjectObjectInitImpl({ ...this.data, features });
    }

    export(): ProjectObjectInit {
        return {
            features: Object.values(this.features)
                .filter((f) => f.value !== undefined)
                .map((f) => ({ id: f.id, value: f.value! })),
            outlets: Object.values(this.outlets).map((o) => o.export()),
        };
    }
}

export interface ProjectScripts {
    readonly scripts: IdMap<ScriptImpl>;
    withScript(path: string, script: ScriptImpl): ProjectScripts;
    export(): { path: string; script: Script }[] | undefined;
    withScriptModifier(path: string, fn: (script: ScriptImpl) => ScriptImpl): ProjectScripts;
    withoutScript(path: string): ProjectScripts;
}

export const DEFAULT_EMPTY_SCRIPT = ScriptImpl.from({ steps: [{ format: 'actions', actions: { items: [] } }] });

export class ProjectScriptsMutable implements ProjectScripts {
    static from(scripts: { path: string; script: Script }[]) {
        return new ProjectScriptsMutable({ scripts: Lists.reduce(scripts, (s) => [s.path, ScriptImpl.from(s.script)]) });
    }

    constructor(private data: { scripts: { [path: string]: ScriptImpl } }) {}

    get scripts() {
        return this.data.scripts;
    }

    withoutScript(path: string) {
        if (!this.data.scripts[path]) return this;
        const scripts = { ...this.data.scripts };
        delete scripts[path];
        return new ProjectScriptsMutable({ ...this.data, scripts });
    }

    withScript(path: string, script: ScriptImpl) {
        if (this.data.scripts[path] === script) return this;
        return new ProjectScriptsMutable({ ...this.data, scripts: { ...this.data.scripts, [path]: script } });
    }

    withScriptModifier(path: string, fn: (script: ScriptImpl) => ScriptImpl) {
        let script = this.data.scripts[path];
        if (!script) script = DEFAULT_EMPTY_SCRIPT;
        script = fn(script);
        return this.withScript(path, script);
    }

    export() {
        return Object.entries(this.data.scripts).map((e) => ({ path: e[0], script: e[1].export() }));
    }
}

export class ProjectScriptsEmpty implements ProjectScripts {
    readonly scripts = Object.freeze({});

    private err(): ProjectScripts {
        throw new Error('this object is non-scriptable');
    }

    // @ts-ignore
    withoutScript(path: string): ProjectScripts {
        return this.err();
    }
    withScript(): ProjectScripts {
        return this.err();
    }

    // @ts-ignore
    withScriptModifier(path: string, fn: (script: ScriptImpl) => ScriptImpl) {
        return this.err();
    }
    export() {
        return undefined;
    }
}

export class ProjectObjectImpl {
    readonly type = 'object';

    constructor(
        private data: {
            uuid: string;
            top: boolean;
            api: ProjectObjectApi;
            label: string;
            tags: SelectedTagsImpl;
            deviceType?: string;
            scripts: ProjectScripts;
            init: ProjectObjectInitImpl;
            impl: ObjectImplementation;
            accessLevel: string;
        },
    ) {}

    get top() {
        return this.data.top;
    }

    get uuid() {
        return this.data.uuid;
    }
    // this in fact is a "sublabel". w/o parent module.label is not useful, and for simple devices it is empty...
    get label() {
        return this.data.label;
    }
    get tags() {
        return this.data.tags;
    }
    get api() {
        return this.data.api;
    }
    get init() {
        return this.data.init;
    }
    get userType() {
        return this.data.deviceType;
    }
    get scripts() {
        return this.data.scripts;
    }
    get impl() {
        return this.data.impl;
    }
    get accessLevel() {
        return this.data.accessLevel;
    }

    get parent(): string | undefined {
        return this.init.outlets[OUTLET_PARENT]?.firstStatic;
    }

    getParent(resolver: (id: string) => ProjectObjectImpl | undefined): ProjectObjectImpl | undefined {
        const parentId = this.parent;
        return parentId ? resolver(parentId) : undefined;
    }

    getChild(id: string, resolver: (id: string) => ProjectObjectImpl | undefined): ProjectObjectImpl | undefined {
        const childId = this.init.outlets[id]?.firstStatic;
        return childId ? resolver(childId) : undefined;
    }

    // getChildren(resolver:(id:string)=>ProjectObjectImpl|undefined) : {[outletId:string]:ProjectObjectImpl[]} {
    //     const children = this.children
    //     return Maps.transform(children, (_,ids)=>ids.map(id=>resolver(id)).filter(notEmpty))
    // }

    hasTag(tag: string) {
        return this.tags.includes(tag);
    }

    withTags(tags: string[]) {
        return new ProjectObjectImpl({ ...this.data, tags: SelectedTagsImpl.from(tags) });
    }

    withLabel(label: string) {
        if (this.data.label === label) return this;
        if (this.parent) {
            throw new Error("cannot change child object's label");
        }
        return new ProjectObjectImpl({ ...this.data, label });
    }

    withScripts(scripts: ProjectScripts) {
        if (this.data.scripts === scripts) return this;
        return new ProjectObjectImpl({ ...this.data, scripts });
    }

    withAccessLevel(accessLevel: string) {
        if (this.data.accessLevel === accessLevel) return this;
        return new ProjectObjectImpl({ ...this.data, accessLevel });
    }

    withApi(api: ProjectObjectApi) {
        if (this.data.api === api) return this;
        if (api.api.id !== this.api.api.id) {
            throw new Error('cannot change to other API id');
        }
        return new ProjectObjectImpl({ ...this.data, api });
    }

    withInit(init: ProjectObjectInitImpl) {
        if (this.data.init === init) return this;
        return new ProjectObjectImpl({ ...this.data, init });
    }

    withUserType(deviceType: string | undefined): ProjectObjectImpl {
        if (this.data.deviceType === deviceType) return this;
        return new ProjectObjectImpl({
            ...this.data,
            deviceType,
        });
    }

    // TODO change into withImpl
    withModuleRef(componentRef: ModuleObjectRef) {
        if (this.data.impl.type === 'module' && this.data.impl.componentRef === componentRef) return this;
        return new ProjectObjectImpl({
            ...this.data,
            impl: {
                type: 'module',
                componentRef,
            },
        });
    }

    public export(): ProjectObject {
        return {
            id: this.data.uuid,
            api: exportApiToSchema(this.data.api.api),
            init: this.init.export(),
            scripts: this.scripts.export(),
            label: this.data.label,
            tags: this.tags.export(),
            deviceType: this.data.deviceType,
            impl: this.data.impl,
            accessLevel: this.data.accessLevel,
        };
    }
}

export function exportApiToSchema(api: ObjectApi): schema.ProtocolPtr {
    if (isInlinedApi(api)) {
        return {
            type: 'spec',
            spec: {
                extending: api.extending?.map((ext) => ext.id),
                events: Object.values(api.self.events),
                methods: Object.values(api.self.methods),
                features: Object.values(api.self.features),
                outlets: Object.values(api.self.outlets).map((o) => ({
                    id: o.id,
                    protocol: {
                        type: 'ref',
                        ref: o.api.id,
                    },
                    label: o.label,
                    readOnly: o.readOnly,
                    maxItems: o.maxItems,
                    bidirectional: o.bidirectional,
                })),
            },
        };
    } else {
        return { type: 'ref', ref: api.id };
    }
}
