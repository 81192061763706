import { serialization } from 'blockly';
import { Workspace } from 'blockly';
import { luaGenerator } from 'blockly/lua';
import log from 'loglevel';
import { Subscription, debounce, timer, startWith, pairwise } from 'rxjs';
import { ProjectImpl, StateProvider } from '@grenton/gm-logic';
import { blocks } from '@grenton/gm-logic';
import { BlocklyWorkspaceRef, triggerWorkspaceChange } from './BlocklyWorkspaceRef';
import { ScriptWithContext } from '@grenton/gm-logic';
import { ObjectScriptRef } from '@grenton/gm-logic';
import { ModeController } from '../backend/abstractModeController';
import { ResolvedPath } from '@grenton/gm-logic';
import { toolboxGrentonCategory } from './toolbox/toolboxObjectCategory';
import { GRENTON_CATEGORY } from './toolbox/toolbox';
import { BlockCreator } from './blockCreator';
import { updateBlocksToReflectProjectState } from './updateBlocksToReflectProjectState';

export class VisualModeController implements ModeController<Record<string, any>> {
    private periodicSyncOnChange?: Subscription;
    private updateGraphOnChange?: Subscription;

    readonly workspaceRef = new BlocklyWorkspaceRef();

    private scriptContextRef?: ObjectScriptRef;

    constructor(
        private project: StateProvider<ProjectImpl | null>,
        private onSave: (visual: string | null) => void,
    ) {
        project.subscribe(() => {
            this.onProjectChange();
        });

        this.workspaceRef.workspace.pipe(startWith(null), pairwise()).subscribe(([_prev, current]) => {
            if (current) {
                current.registerToolboxCategoryCallback(
                    GRENTON_CATEGORY,
                    toolboxGrentonCategory(() => this.scriptContextRef),
                );
            }

            this.periodicSyncOnChange?.unsubscribe();
            this.periodicSyncOnChange = this.workspaceRef.changeEvent.pipe(debounce(() => timer(100))).subscribe((e) => {
                log.info('workspace change -> periodic serialization', e);
                this.workspaceRef.withWorkspace((workspace) => this.saveWorkspace(workspace));
            });

            this.updateGraphOnChange?.unsubscribe();
            this.updateGraphOnChange = this.workspaceRef.changeEvent.subscribe((e) => {
                log.info('workspace change -> updateBlocksToReflectProjectState', e);
                this.workspaceRef.withWorkspace((workspace) => updateBlocksToReflectProjectState(workspace, this.project.value!, this.scriptContextRef!));
            });
        });
    }

    generateDerived() {
        return (
            this.workspaceRef.withWorkspace((workspace) => {
                return luaGenerator.workspaceToCode(workspace);
            }) || ''
        );
    }

    /*
     * loop:
     * 1. user changes action
     * 2. action generates derived visual json
     * 3. setContent(derivedJson)
     * 4. workspaceChange
     * 5. onSave
     *
     */
    setContent(currentJson: Record<string, any> | null) {
        return this.workspaceRef.withWorkspace((workspace) => {
            if (!this.scriptContextRef) return;
            workspace.clear();

            serialization.workspaces.load(currentJson || {}, workspace);
            this.onProjectChange(true);
        }, true);
    }

    private saveWorkspace(workspace: Workspace) {
        const workspaceJson = serialization.workspaces.save(workspace);
        const visual = Object.entries(workspaceJson).length ? JSON.stringify(workspaceJson) : null;
        log.info('save workspace');
        this.onSave(visual);
    }

    /**
     * refresh workspace to reflect all changes in the project
     * this is a common problem for all editors (maybe except 'text' one) - to render content, we need
     * some mutable data derived from the project, namely - names of objects and methods.
     * in script model (e.g. workspace) we store IDs only, so they need to be translated into names every time
     * when project changes.
     * @returns
     */
    onProjectChange(_quiet = false) {
        this.workspaceRef.withWorkspace((workspace) => {
            if (!this.scriptContextRef || !this.project.value) return;
            log.info('visual.onProjectChange');

            // this internally updates blocks.
            // we SHOULD save workspace if it changed essentially, but it is tricky to discover such change.
            updateBlocksToReflectProjectState(workspace, this.project.value, this.scriptContextRef);
            // although we mostly update decorators here, this operation may still affect core code (e.g. if target object has been deleted)
            //if (!quiet) this.saveWorkspace(workspace);

            triggerWorkspaceChange(workspace);
        });
    }

    onBeforeScriptChange() {
        // this should not be necessary, as we save after each change
        // this.workspaceRef.withWorkspace((workspace) => {
        //     // ensure there's no unsaved changes
        //     if (this.scriptContextRef) {
        //         this.saveWorkspace(workspace);
        //     }
        // })
    }

    // TODO switch to editedScriptRef, and call it only when it changes.
    onScriptChange(scriptContext: ScriptWithContext) {
        log.debug('blockly - new script', scriptContext.ref);
        this.scriptContextRef = scriptContext.ref;

        // refresh toolbox, it might change
        this.workspaceRef.withWorkspace((workspace) => {
            workspace.getToolbox()?.refreshSelection();
        });
        const currentJson = scriptContext.getScript()?.visual || null;
        this.setContent(currentJson ? JSON.parse(currentJson) : {});
    }

    addScriptlet(relative: ResolvedPath, opts?: { set?: boolean }) {
        this.workspaceRef.withWorkspace((workspace) => {
            const tail = relative.tail;

            const creator = new BlockCreator(workspace);
            switch (tail?.type) {
                case 'object':
                    {
                        creator.addEntity(relative.serialize());
                    }
                    break;
                case 'outlet':
                    creator.addEntity(relative.serialize());
                    break;
                case 'method':
                    {
                        const targetBlock = creator.addEntity(relative.withoutTail().serialize());
                        creator.newBlock(blocks.ApiMethodInvoke.Type, (block: blocks.BlockWithDelegate<blocks.ApiMethodInvoke.Delegate>) => {
                            block.delegate.connectToEntity(targetBlock, tail.id);
                        });
                    }
                    break;
                case 'feature':
                    {
                        const targetBlock = creator.addEntity(relative.withoutTail().serialize());
                        if (opts?.set) {
                            creator.newBlock(blocks.ApiFeatureSet.Type, (block: blocks.BlockWithDelegate<blocks.ApiFeatureSet.Delegate>) => {
                                block.delegate.connectToEntity(targetBlock, tail.id);
                            });
                        } else {
                            creator.newBlock(blocks.ApiFeatureGet.Type, (block: blocks.BlockWithDelegate<blocks.ApiFeatureGet.Delegate>) => {
                                block.delegate.connectToEntity(targetBlock, tail.id);
                            });
                        }
                    }
                    break;
            }
        });
    }
}
