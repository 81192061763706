import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { ProjectImpl } from '../model';

export interface EditTagCategoryForm {
    name: string;
    color: string;
    multiple: boolean;
}

export class EditTagCategoryCommand implements Command {
    readonly type = 'EditTagCategoryCommand';
    constructor(readonly data: { form: EditTagCategoryForm }) {}
}

export class EditTagCategoryCommandHandler implements CommandHandler<EditTagCategoryCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'EditTagCategoryCommand';
    }

    execute(cmd: EditTagCategoryCommand): void {
        this.update((project) => {
            // preserve existing tags
            const values = project.tags.getCategory(cmd.data.form.name)?.tags || [];
            return project.withTagCategory(cmd.data.form.name, cmd.data.form.color, Boolean(cmd.data.form.multiple), values);
        });
    }
}
