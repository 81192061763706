import { ProjectItemPath } from '@grenton/gm-common';
import { Block, BlockSvg, Workspace } from 'blockly';
import { blocks } from '@grenton/gm-logic';

export class BlockCreator {
    constructor(private workspace: Workspace) {}

    newBlock<T>(name: string, init?: (block: T) => void): Block {
        const block = this.workspace.newBlock(name);
        if (init) {
            init(block as unknown as T);
        }
        if ('initSvg' in block) {
            (block as BlockSvg).initSvg();
            (block as BlockSvg).render();
        }
        return block;
    }

    addEntity(ref: ProjectItemPath) {
        return this.newBlock(blocks.ApiEntityWithApiBlock.Type, (block: blocks.BlockWithDelegate<blocks.ApiEntityWithApiBlock.Delegate>) => {
            block.delegate.setPath(ref);
        });
    }
}
