import { Block } from 'blockly';
import { ActionCall, ActionOutput, ObjectApi } from '@grenton/gm-common';
import { BlockCreator } from '../visual/blockCreator';
import { blocks } from '@grenton/gm-logic';
import { ResolvedPath } from '@grenton/gm-logic';

export type ActionCodeGenerator<Model> = (model: Model, path: ResolvedPath) => ActionOutput;

export function generateBlocksForMethodCalls(calls: ActionCall[], path: ResolvedPath, creator: BlockCreator, api: ObjectApi): Block[] {
    const actionBlocks: Block[] = [];
    for (const call of calls) {
        const targetBlock = creator.addEntity(path.serialize());
        const actionBlock = api.flat.features[call.callable]
            ? creator.newBlock(blocks.ApiFeatureSet.Type, (block: blocks.BlockWithDelegate<blocks.ApiMethodInvoke.Delegate>) => {
                  block.delegate.connectToEntity(targetBlock, call.callable);
              })
            : creator.newBlock(blocks.ApiMethodInvoke.Type, (block: blocks.BlockWithDelegate<blocks.ApiMethodInvoke.Delegate>) => {
                  block.delegate.connectToEntity(targetBlock, call.callable);
              });
        actionBlocks.push(actionBlock);
    }
    return actionBlocks;
}
