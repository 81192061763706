import { ObjectOutletForm, OutletResolver, convertOutletSelector, resolvePath } from '@grenton/gm-logic';
import { ProjectImpl, ProjectObjectImpl } from '@grenton/gm-logic';
import { fabric, ANONYMOUS_CONTROLLER_SUFFIX } from '@grenton/gm-common';

type ObjectLink = fabric.admin.objectRead.ObjectLink;

export type ObjectReferences = Map<string, fabric.OutletRefs>;

export type SystemReferences = Map<string, ObjectReferences>;

export class ReferenceCalc {
    constructor(private outletResolver: OutletResolver) {}

    calculate(p: ProjectImpl): SystemReferences {
        const sysRefs = new Map<string, ObjectReferences>();

        //TODOX
        Object.values(p.objects).forEach((entity) => {
            const objectOutletRefs = new Map<string, fabric.OutletRefs>();
            sysRefs.set(entity.uuid, objectOutletRefs);

            const outlets = Object.values(entity.api.api.flat.outlets);
            for (const outlet of outlets) {
                const referencedObjects = Object.keys(
                    this.outletResolver.findObjects(p, entity.tags.one, outlet.api.id, entity.init.outlets[outlet.id]?.dynamicRefs),
                );

                // TODO support multiple owner tags
                objectOutletRefs.set(outlet.id, {
                    devices: Object.keys(entity.init.outlets[outlet.id]?.dynamicRefs.types || []),
                    refs: referencedObjects,
                });
            }
        });

        return sysRefs;
    }
}

export function calculateLinks(p: ProjectImpl, object: ProjectObjectImpl) {
    const calc = new ReferenceCalc(new OutletResolver());

    const refs = calc.calculate(p);
    return {
        from: [...calculateReferrersViaOutlets(object.uuid, refs), ...calculateReferrersViaCode(p, object.uuid)],
        to: [...calculateReferencesViaOutlets(object, refs), ...calculateReferencesViaCode(p, object)],
    };
}

// TODO this was moved from editor-controller and it is probably duplicated somewhere here
export function findOutletObjects(p: ProjectImpl, outletOwnerTag: string, form: ObjectOutletForm): ProjectObjectImpl[] {
    const outletResolver = new OutletResolver();
    return p && form.apiRef ? outletResolver.findObjects(p, outletOwnerTag, form.apiRef, convertOutletSelector(form.config.selector)) : [];
}

/**
 * calculate object -> outlet link (so, reverse reference)
 */
function calculateReferrersViaOutlets(objectId: string, refs: SystemReferences): ObjectLink[] {
    const reversed: ObjectLink[] = [];
    refs.forEach((objectRefs, ownerId) => {
        objectRefs.forEach((outletRefs, outletId) => {
            if (outletRefs.refs.find((id) => id === objectId)) {
                reversed.push({ id: ownerId, path: outletId, direct: false });
            }
        });
    });
    return reversed;
}

function calculateReferrersViaCode(p: ProjectImpl, objectId: string): ObjectLink[] {
    const callers: ObjectLink[] = [];
    Object.values(p.objects).forEach((object) => {
        Object.entries(object.scripts.scripts)
            .filter((e) => e[1].format === 'actions')
            .forEach((e) => {
                e[1].actions?.items.forEach((item) => {
                    const path = resolvePath(item.target, p.objectResolver, object.uuid);
                    if (path.tail?.type === 'object' && path.tail.id === objectId) {
                        // this script targets our object
                        // if (item.output.calls.find(call=>call.method === scriptContextRef.scriptRef.path)) {
                        //     callers.push({object, scriptPath:e[0], action: item})
                        //     path.rootObject
                        // }
                        callers.push({ id: object.uuid, path: e[0], direct: true });
                    } else if (path.tail?.type === 'outlet') {
                        // can we figure out here that objectId is referenced by the outlet?
                        // yes.
                        // should we show such relation and omit the outlet?
                        // not sure.
                    }
                });
            });
    });
    return callers;
}

function calculateReferencesViaOutlets(obj: ProjectObjectImpl, refs: SystemReferences): ObjectLink[] {
    if (obj.impl.type !== 'script' && obj.impl.type !== 'system') return [];

    const outletRefs = refs.get(obj.uuid);
    if (outletRefs) {
        return Array.from(outletRefs.entries())
            .map((e) => e[1].refs.map((id) => ({ id, path: e[0], direct: false })))
            .flat();
    } else {
        return [];
    }
}

function calculateReferencesViaCode(p: ProjectImpl, object: ProjectObjectImpl): ObjectLink[] {
    const realObj = object.impl.type === 'script' ? object : p.objectResolver(object.uuid + ANONYMOUS_CONTROLLER_SUFFIX);
    if (!realObj) return [];

    const callers: ObjectLink[] = [];
    Object.entries(realObj.scripts.scripts)
        .filter((e) => e[1].format === 'actions')
        .forEach((e) => {
            e[1].actions?.items.forEach((item) => {
                const path = resolvePath(item.target, p.objectResolver, realObj.uuid);
                if (path.tail?.type === 'object') {
                    callers.push({ id: path.tail.id, path: e[0], direct: true });
                }
            });
        });
    return callers;
}
