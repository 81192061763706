import { Lists, Maps, fabric } from '@grenton/gm-common';
import { Observable, Subject, bufferCount } from 'rxjs';
import { ProjectImpl, ProjectObjectImpl, ProjectScripts, exportApiToSchema } from '@grenton/gm-logic';
import log from 'loglevel';
import { ObjectReferences, ReferenceCalc } from './reference-calc';
import { OutletResolver } from '@grenton/gm-logic';

enum ObjectStatus {
    REMOVED,
    MODIFIED,
    CREATED,
    UNMODIFIED,
}
/*
 * to calculate references, we need to run such calculation for any change in the system
 * when we detect a change in object references, we should trigger config change for this object
 * so basically we need a map (entity)->(references)
 */
export class ObjectConfigPublisher {
    readonly pub = new Subject<fabric.ObjectConfigChangeMessage | fabric.ObjectConfigRemovedMessage | fabric.ObjectStateMessage | fabric.ObjectLogicMessage>();

    private referenceCalc: ReferenceCalc;
    private prevRefs = new Map<string, Map<string, fabric.OutletRefs>>();

    constructor(project: Observable<ProjectImpl | null>) {
        project.pipe(bufferCount(2, 1)).subscribe(([prev, curr]) => this.compare(prev!, curr!));
        this.referenceCalc = new ReferenceCalc(new OutletResolver());
    }

    private compare(prev: ProjectImpl, curr: ProjectImpl) {
        log.debug('project config change');

        const allprev = Object.values(prev.objects);
        const allcurr = Object.values(curr.objects);
        const allcurr_byid = Lists.reduce(allcurr, (obj) => [obj.uuid, obj]);

        const currentRefs = this.referenceCalc.calculate(curr);

        const objectStatus = new Map<string, ObjectStatus>();
        allprev.forEach((p) => {
            const c = allcurr_byid[p.uuid];
            if (p && !c) {
                objectStatus.set(p.uuid, ObjectStatus.REMOVED);
                this.onObjectRemoval(p);
                //removal
            } else if (c && p !== c) {
                //change
                objectStatus.set(p.uuid, ObjectStatus.MODIFIED);
                this.onObjectChange(c, currentRefs.get(p.uuid), p);
            } else {
                objectStatus.set(p.uuid, ObjectStatus.UNMODIFIED);
            }
        });
        Object.values(curr.objects).forEach((c) => {
            if (objectStatus.get(c.uuid) === undefined) {
                objectStatus.set(c.uuid, ObjectStatus.CREATED);
                this.onObjectCreation(c, currentRefs.get(c.uuid));
            }
        });
        objectStatus.forEach((status, uuid) => {
            if (status === ObjectStatus.UNMODIFIED) {
                // check if references have changed, trigger update in such a case
                const p = this.prevRefs.get(uuid);
                const c = currentRefs.get(uuid);
                // compare maps
                if (!(p && c && Maps.areEqual(p, c, (pr, cr) => Lists.areEqual(pr.refs, cr.refs)))) {
                    const obj = allcurr_byid[uuid];
                    if (obj) {
                        objectStatus.set(uuid, ObjectStatus.MODIFIED);
                        this.onObjectChange(obj, c);
                    }
                }
            }
        });
        this.prevRefs = currentRefs;
    }

    private onObjectCreation(curr: ProjectObjectImpl, refs?: ObjectReferences) {
        this.onObjectChange(curr, refs);
    }

    private onObjectRemoval(prev: ProjectObjectImpl) {
        log.debug('removed object', prev.uuid);
        this.broadcastConfigChange({
            type: 'config-removed',
            data: {
                uuid: prev.uuid,
                accessLevel: prev.accessLevel,
            },
        });
    }

    private onObjectChange(curr: ProjectObjectImpl, refs?: ObjectReferences, prev?: ProjectObjectImpl) {
        function configChanged() {
            if (!prev) return true;
            if (prev.label != curr.label) return true;
            if (prev.tags != curr.tags) return true;
            if (prev.api != curr.api) return true;
            // compute references here?
            return false;
        }

        if (configChanged()) {
            this.broadcastConfigChange({
                type: 'config-change',
                data: {
                    uuid: curr.uuid,
                    config: {
                        accessLevel: curr.accessLevel, //TODO tmp
                        name: curr.label,
                        tags: curr.tags.selected,
                        device: curr.userType,
                        api: exportApiToSchema(curr.api.api),
                        references: Object.fromEntries(refs || []),
                        impl: curr.impl,
                    },
                },
            });
        }

        if (curr.scripts !== prev?.scripts) {
            this.onLogicChange(curr.uuid, curr.accessLevel, curr.scripts);
        }
    }

    // @ts-ignore
    private onLogicChange(uuid: string, accessLevel: string, projectScripts: ProjectScripts) {
        // @ts-ignore
        const scripts = Maps.transform(projectScripts.scripts, (id, s) => ({ type: 'lua', script: s.code }) as fabric.ExecutableLuaScript); //TODOX

        this.broadcastLogicChange({
            type: 'logic',
            data: {
                uuid,
                logic: {
                    scripts,
                },
            },
        });
    }

    private broadcastConfigChange(msg: fabric.ObjectConfigChangeMessage | fabric.ObjectConfigRemovedMessage) {
        this.pub.next(msg);
    }

    private broadcastLogicChange(msg: fabric.ObjectLogicMessage) {
        this.pub.next(msg);
    }
}
