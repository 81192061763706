import { Maps, isApiExtending } from '@grenton/gm-common';
import { ProjectImpl, ObjectSelectorImpl, ProjectObjectImpl, isAnonymousController } from './model';

// this refers to relative TAG, not anonymous controller suffix
export const OWNER_TAG = '$ctrl';

/**
 * returns list of (dedup) objects that matches given outlet based on an optional outlet selector.
 * Objects are sorted by name, since this is the natural order of objects in a selector.
 * Outlet always has a protocol defined, so all returned objects MUST comply with this protocol.
 *
 * Additional filtering is done based on a selector:
 * Outlet Selector may include:
 * - explicit object ids
 * - tags (regular or relative ones)
 * - functional types
 *
 * Filtering logic is as follows:
 * - always include objects explicitly selected by id
 * - include objects that met all of following criteria
 *      - if selector defines tags, objects must have all tags from selector
 *      - if selector defines types, objects must have one of the types from selector
 *
 * // TODO this should be a function, and returns a LIST of objects already sorted by name.
 */
export class OutletResolver {
    findObjects(project: ProjectImpl, ownerTag: string | undefined, outletApi: string, selector?: ObjectSelectorImpl): ProjectObjectImpl[] {
        if (!selector) return [];

        const compatible = (obj: ProjectObjectImpl) => obj && !isAnonymousController(obj.uuid) && isApiExtending(obj.api.api, outletApi);

        // use AND operator to filter objects by tags
        const selectedTags = Maps.reduceKeyMap(selector.tags);
        const selectedTypes = Maps.reduceKeyMap(selector.types);
        const selectorHasTags = Boolean(selectedTags.length);
        const selectorHasTypes = Boolean(selectedTypes.length);

        let objectsSelectedImplicitly: ProjectObjectImpl[] = selectorHasTags || selectedTypes ? Maps.values(project.objects).filter(compatible) : [];

        selectedTags.forEach((tag) => {
            if (tag.startsWith(OWNER_TAG)) {
                if (!ownerTag) return;
                tag = tag.replace(OWNER_TAG, ownerTag);
            }
            objectsSelectedImplicitly = objectsSelectedImplicitly.filter((obj) => obj.tags.includes(tag));
        });

        if (selectorHasTypes) {
            objectsSelectedImplicitly = objectsSelectedImplicitly.filter((obj) => obj.userType && selectedTypes.includes(obj.userType));
        }

        // include all named objects if compatible
        const objectsSelectedExplicitly: ProjectObjectImpl[] = [];

        Maps.reduceKeyMap(selector.ids).forEach((id) => {
            if (objectsSelectedImplicitly.find((obj) => obj.uuid === id)) return; //dedup
            const obj = project.getObjectById(id);
            if (obj && compatible(obj)) objectsSelectedExplicitly.push(obj);
        });

        return [...objectsSelectedExplicitly, ...objectsSelectedImplicitly].toSorted((a, b) => a.label.localeCompare(b.label));
    }
}
