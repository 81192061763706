import { ProjectTags, Lists } from '@grenton/gm-common';
import { ProjectTagsCategory, ProjectTagsCategoryValues } from './tags-category';

export const TAG_SEPARATOR = ':';
export const LOC_PREFIX = `loc${TAG_SEPARATOR}`;

export function parseTag(tag?: string) {
    const t = tag?.split(TAG_SEPARATOR);

    if (!t || t.length !== 2) {
        return { category: '', value: '' };
    } else {
        return { category: t[0]!.trim(), value: t[1]!.trim() };
    }
}

// TODO little hack to keep "loc:" first.
// do we need category.order ?
export function sortTags(t1: string, t2: string) {
    if (t1 === '') return 1;
    if (t2 === '') return -1;
    if (t1.startsWith(LOC_PREFIX) && !t2.startsWith(LOC_PREFIX)) return -1;
    if (t2.startsWith(LOC_PREFIX) && !t1.startsWith(LOC_PREFIX)) return 1;
    return t1.localeCompare(t2);
}

export class ProjectTagsImpl {
    static from(tags: ProjectTags) {
        return new ProjectTagsImpl(
            Lists.reduce(tags, (tag) => [
                tag.name,
                new ProjectTagsCategory({
                    name: tag.name,
                    multiple: tag.multiple,
                    color: tag.color,
                    values: ProjectTagsCategoryValues.from(tag.values),
                }),
            ]),
        );
    }

    constructor(private data: { [category: string]: ProjectTagsCategory }) {}

    merge(t: ProjectTagsImpl) {
        return new ProjectTagsImpl({ ...t.data, ...this.data });
    }

    getCategory(name: string) {
        return this.data[name];
    }

    hasCategory(name: string) {
        return Boolean(this.data[name]);
    }

    withCategoryImpl(c: ProjectTagsCategory) {
        return new ProjectTagsImpl({ ...this.data, [c.name]: c });
    }

    removeCategory(categoryName: string): ProjectTagsImpl {
        if (this.data[categoryName]) {
            const newData = { ...this.data };
            delete newData[categoryName];
            return new ProjectTagsImpl(newData);
        }
        return this;
    }

    withCategory(name: string, color: string, multiple = true, values: string[] = []) {
        return new ProjectTagsImpl({
            ...this.data,
            [name]: new ProjectTagsCategory({ name, color, multiple, values: ProjectTagsCategoryValues.from(values) }),
        });
    }

    withTag(tag: string, include = true) {
        const ctag = parseTag(tag);
        const category = this.data[ctag.category];
        if (category) {
            return new ProjectTagsImpl({
                ...this.data,
                [ctag.category]: category.withValues(category.values.withTag(ctag.value, include)),
            });
        }
        return this;
    }

    removeTag(tagToRemove: string, categoryName: string): ProjectTagsImpl {
        const category = this.data[categoryName];

        if (category) {
            const newValues = category.values.all.filter((tag) => tag !== tagToRemove);
            const newCategoryValues = ProjectTagsCategoryValues.from(newValues);
            return new ProjectTagsImpl({
                ...this.data,
                [categoryName]: new ProjectTagsCategory({
                    name: category.name,
                    color: category.color,
                    multiple: category.multiple,
                    values: newCategoryValues,
                }),
            });
        }
        return this;
    }

    getColor = (categoryName?: string) => {
        const category = this.data[categoryName || ''];
        return category ? category.color : '#aaa';
    };

    export(): ProjectTags {
        return Object.values(this.data).map((c) => c.export());
        //, (catName, cat) => ({ color: cat.color, multiple: cat.multiple, values: Object.entries(cat.values).filter(e => e[1] === true).map(e => e[0]) }))
    }

    get all(): string[] {
        const all: string[] = [];
        Object.entries(this.data).forEach((cat) => {
            cat[1].values.all.forEach((tag) => {
                all.push(`${cat[0]}:${tag}`);
            });
        });
        return all;
    }

    byCategoryName(categoryName: string): string[] {
        const all: string[] = [];
        const cat = this.data[categoryName];
        cat?.values.all.forEach((tag) => {
            all.push(`${cat.name}:${tag}`);
        });
        return all;
    }

    get categories(): ProjectTagsCategory[] {
        return Object.values(this.data);
    }
}
