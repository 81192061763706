import { Components, Theme } from '@mui/material';
import { spacing } from '../size';

export const MuiDialogTitle: Components<Omit<Theme, 'components'>>['MuiDialogTitle'] = {
    defaultProps: {
        // design suggests "l" in standalone dialogs and "m" in side dialogs
        variant: 'l',
    },
    styleOverrides: {
        //@ts-ignore
        root: ({ _ownerState, _theme }) => ({
            paddingLeft: spacing.M,
            paddingRight: spacing.M,
            paddingTop: spacing.S,
            paddingBottom: spacing.S,
        }),
    },
};
