import {
    ensureLabelIsUnique,
    createSoftwareObjectLabel,
    createSoftwareObjectLabeler,
    ProjectObjectInit,
    ProjectObject,
    AccessLevels_Unblocked,
    createAnonymousCtrl,
    isApiExtending,
    IdMap,
} from '@grenton/gm-common/src';
import { newObjectId } from '../id';
import { ProjectFirmwareImpl } from '../model';
import { ComponentEntry, ComponentEntryImpl, ObjectEntry, ObjectEntryImpl } from './createObjectsFromModuleComponent';
import { importObject } from './importObject';
import { createComponentInstanceImpl } from './createComponentInstanceImpl';

/**
 * create objects for virtual (system/script/external) component
 *
 * differences with module components:
 * 1. virtual components provides only one object (easy to unify)
 *
 * 2. virtual components are not instantiated in project, they are used to create objects and information
 *    about where these objects originate from disappear! this is unfortunate, because we might need such info,
 *    e.g. to perform automatic updates of script updates or creating user copies of such components. This has to change.
 *
 * 3. virtual components can auto-create other virtual components, and link to them via their dynamic outlets.
 *    objects provided by modules have no dynamic outlets so this is not possible.
 *
 * 4. currently module objects can create subobjects (linked via static outlet refs), but this is driven by protocol,
 *    not by component, so it is entirely possible to have the same feature for virtual components.
 *    however - for script components, I think we may skip creating anonymous controllers for subobjects, because it is
 *    natural that script component will program them via its outlets. This creates a problem with visualisation on a tree.
 *    and creates a need for script component to attach to particular objects in outlets, via indices, as such: "timer[1].onStart".
 *    we do not have such capability, and it seems to be tricky & unnecessary...
 *    I'd prefer to stay with outlet-as-selection approach for controllers.
 *
 * if I implement this w/o changes, controllers will render subobjects w/o event handlers, because these event handlers are actually
 * coming from anonymous controller linked to a subobject. This may be fine, but we need to show outlet events aside.
 */

export type CreateObjectsFromVirtualComponent = typeof createObjectsFromVirtualComponent;

export function createObjectsFromVirtualComponent(
    firmware: ProjectFirmwareImpl,
    componentRef: string,
    label: string | null,
    hasLabel: (label: string) => boolean,
    createOutletFillers: boolean,
    tags?: string[],
): ComponentEntry {
    const componentSpec = firmware.getComponent(componentRef);
    if (!componentSpec) throw new Error(`firmware does not define component "${componentRef}"`);

    if (componentSpec.spec.type === 'module') {
        throw new Error(`component ${componentRef} is a module`);
    }
    const component = createComponentInstanceImpl(componentRef, componentSpec.spec.type);
    const _label = ensureLabelIsUnique(label || createSoftwareObjectLabel(componentSpec.spec), hasLabel);
    const companionLabeler = createSoftwareObjectLabeler(_label);
    const id = newObjectId();

    const providedObject = componentSpec.spec.provides.object;
    if (!providedObject) throw new Error('component does not define a single provided object');

    const init: ProjectObjectInit = {
        outlets:
            providedObject.init?.outlets?.map((o) => ({
                id: o.id,
                refs: {
                    type: 'dynamic',
                    value: o.dynamicRefs || {},
                },
            })) || [],
        features: providedObject.init?.features || [],
    };

    const po: ProjectObject = {
        api: providedObject.protocol,
        impl: {
            type: componentSpec.spec.type,
            componentRef: {
                componentId: component.uuid,
            },
        },
        init,
        id: id,
        label: _label,
        tags,
        accessLevel: AccessLevels_Unblocked,
    };
    let object = importObject(firmware, po);
    const createdObjects: ObjectEntry[] = [];
    const createdComponents: ComponentEntry[] = [];

    // create fillers for provided outlets
    // TODO we need to enable this for non-singletons, and "provided" should be numeric.
    const autoCreateOutletFillers = createOutletFillers ? componentSpec.spec.addons?.autoCreate || [] : [];

    autoCreateOutletFillers.forEach((aco) => {
        const outlet = object.api.api.flat.outlets[aco.outletId];

        const acComponentSpec = firmware.getComponent(aco.componentId);
        if (!acComponentSpec) throw new Error(`firmware does not define component "${aco.componentId}"`);

        // for now let's limit autoCreation to system components only (like timer or scheduler)
        // but generally we can extend this to all types (or even module, if user prefers to create project first)
        if (outlet && acComponentSpec.spec.type === 'system') {
            const api = acComponentSpec.spec.provides.object.protocol;
            const candidateApi = firmware.resolveApiRef(api);
            if (candidateApi && isApiExtending(candidateApi, outlet.api.id)) {
                const count = Math.min(outlet.maxItems || 100, aco.count || 1);
                const parentTags = object.tags.export();
                const idsSelector: IdMap<boolean> = {};
                for (let index = 1; index <= count; index++) {
                    const companionObjectLabel = companionLabeler({ multiObjectEntry: { id: aco.outletId, count }, index });

                    // for now we support only ONE provided object per system/software component (+ optional companions)
                    const createdCmpEntry = createObjectsFromVirtualComponent(firmware, aco.componentId, companionObjectLabel, hasLabel, true, parentTags);
                    if (createdCmpEntry && createdCmpEntry.objects[0]) {
                        createdComponents.push(createdCmpEntry);
                        idsSelector[createdCmpEntry.objects[0].object.uuid] = true;
                    }
                }
                // link auto-created objects to the outlet
                object = object.withInit(
                    object.init.withOutletModifier(outlet.id, (outlet) =>
                        outlet.withSelector({
                            ids: idsSelector,
                            tags: {},
                            types: {},
                        }),
                    ),
                );
            }
        }
    });

    if (object.impl.type != 'script') {
        createdObjects.push(new ObjectEntryImpl(importObject(firmware, createAnonymousCtrl(po))));
    }

    return new ComponentEntryImpl(component).addObject(new ObjectEntryImpl(object, createdObjects, createdComponents));
    //new ComponentObjectsImpl(component).add(new ObjectWithCompanions(object, companions));
}
