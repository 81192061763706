import {
    Box,
    FormLabel,
    Stack,
    Typography
} from "@mui/material";
import type {
    TitleProps,
    GetHintProps,
    LabeledFieldProps,
    FieldErrorMessagesProps
} from './types';



export function Title({label}: TitleProps) {
    return (
        <Typography mt={1} mb={1} ml={3} variant="h5">{label}</Typography>
    );
}

export function getHint({feature}: GetHintProps) {
    let hint = feature.unit;
    if (feature.minimum !== undefined || feature.maximum !== undefined) {
        hint += " [" + (feature.minimum || "...") + "-" + (feature.maximum || "...") + "]";
    }
    return hint;
}



export function LabeledField({label, field}: LabeledFieldProps) {
    return (
        <Stack sx={{padding: 1, alignContent: 'start', borderRadius: 2, background: '#eaeaea'}} direction="column">
            {typeof label === 'string' ? <FormLabel>{label}</FormLabel> : label}
            {field}
        </Stack>
    )
}

export function FieldErrorMessages({errors}: FieldErrorMessagesProps) {
    if (errors.length === 0) {
        return null
    } else {
        return (
            <Stack>
                {errors.map((error, index) => <Box sx={{color: 'red'}} key={index}>{error}</Box>)}
            </Stack>
        )
    }
}