import { Project, ProjectRevision, REF_SELF, uuid, type I18nLang } from '@grenton/gm-common';
import { ObjectID } from '@grenton/gm-common';
import { IdMap, Maps } from '@grenton/gm-common';
import { ProjectHardwareImpl } from './hardware';
import { ProjectDeviceModuleInstanceImpl } from './module';
import { ProjectObjectImpl } from './object';
import { ProjectTagsImpl } from './tags';
import { ProjectSecurityImpl } from './security';
import { ProjectFirmwareImpl } from './firmware';
import { ProjectI18nImpl } from './i18n';
import { CURRENT_CONFIGURATION_SCHEMA_VERSION } from '@grenton/contract';
import { ComponentEntry, ObjectEntry } from '../builders';

export type ObjectResolver = (id: string) => ProjectObjectImpl | undefined;

export const GM_AUTHOR = 'gm';

export class ProjectRevisionsImpl {
    static revision(note?: string): ProjectRevision {
        return { tag: uuid.short(), author: GM_AUTHOR, ts: new Date().toISOString(), note };
    }

    constructor(private revisions: ProjectRevision[]) {
        if (revisions.length === 0) throw new Error('empty revisions');
    }

    get head(): ProjectRevision {
        return this.revisions[0]!;
    }

    get all() {
        return this.revisions;
    }

    withNewRevision(rev: ProjectRevision): ProjectRevisionsImpl {
        return new ProjectRevisionsImpl([rev, ...this.revisions]);
    }

    export(): ProjectRevision[] {
        return this.revisions;
    }
}

export class ProjectImpl {
    constructor(
        readonly data: {
            uuid: string;
            firmware: ProjectFirmwareImpl;
            revisions: ProjectRevisionsImpl;
            label: string;
            i18n: ProjectI18nImpl;
            tags: ProjectTagsImpl;
            objects: IdMap<ProjectObjectImpl>;
            modules: IdMap<ProjectDeviceModuleInstanceImpl>;
            hardware: ProjectHardwareImpl;
            security: ProjectSecurityImpl;
        },
    ) {}

    export(): Project {
        return {
            $schema: CURRENT_CONFIGURATION_SCHEMA_VERSION,
            firmware: this.data.firmware.version,
            id: this.uuid,
            revisions: this.data.revisions.export(),
            label: this.label,
            i18n: this.data.i18n.export(),
            tags: this.tags.export(),
            components: this.modules.map((m) => m.export()),
            objects: Object.values(this.data.objects).map((o) => o.export()),
            hardware: this.hardware.export(),
            security: this.data.security.export(),
        };
    }

    // TODO for now. pair code should change with each new session, to be hard to intercept
    get pairCode() {
        return this.data.uuid.substring(0, 8).toUpperCase();
    }
    get uuid() {
        return this.data.uuid;
    }
    get firmware() {
        return this.data.firmware;
    }
    get revisions() {
        return this.data.revisions;
    }
    get label() {
        return this.data.label;
    }
    get i18n() {
        return this.data.i18n;
    }
    get objects() {
        return this.data.objects;
    }
    get tags() {
        return this.data.tags;
    }
    get hardware() {
        return this.data.hardware;
    }
    get modules() {
        return Maps.values(this.data.modules);
    }
    get security() {
        return this.data.security;
    }

    //TODO silly. remove?
    get empty() {
        return Object.keys(this.data.objects).length === 0 && this.hardware.configuration.clus.length === 0;
    }

    withFirmware(firmware: ProjectFirmwareImpl): ProjectImpl {
        return new ProjectImpl({ ...this.data, firmware });
    }

    withObjectsModifier(callback: (obj: ProjectObjectImpl) => ProjectObjectImpl): ProjectImpl {
        let p: ProjectImpl = this;
        Object.values(this.data.objects).forEach((o) => {
            const o2 = callback(o);
            if (o2 !== o) {
                p = p.withObject(o2);
            }
        });
        return p;
    }

    readonly objectResolver: ObjectResolver = (id: string) => this.getObjectById(id);

    withNewRevision(rev: ProjectRevision): ProjectImpl {
        return new ProjectImpl({ ...this.data, revisions: this.data.revisions.withNewRevision(rev) });
    }

    merge(p: ProjectImpl) {
        return new ProjectImpl({
            ...this.data,
            tags: this.tags.merge(p.tags),
            objects: { ...p.data.objects, ...this.data.objects },
            modules: { ...p.data.modules, ...this.data.modules },
            hardware: this.hardware.merge(p.hardware),
        });
    }

    getModuleById(id: string): ProjectDeviceModuleInstanceImpl | undefined {
        return this.data.modules[id];
    }

    getObjectById(id: string): ProjectObjectImpl | undefined {
        return this.data.objects[id];
    }

    getAllObjectsByComponent(componentId: string): ProjectObjectImpl[] {
        return Object.values(this.objects).filter((o) => o.impl.componentRef.componentId === componentId);
    }

    get topObjects(): ProjectObjectImpl[] {
        return Object.values(this.objects).filter((o) => o.top);
    }

    get topObjectsByComponent(): { [componentId: string]: ProjectObjectImpl[] } {
        return this.topObjects.reduce(
            (acc, object) => {
                const id = object.impl.componentRef.componentId || '';
                return { ...acc, [id]: (acc[id] || []).concat([object]) };
            },
            {} as { [componentId: string]: ProjectObjectImpl[] },
        );
    }

    private getTopObjectByLabel(label: string) {
        for (const obj of this.topObjects) {
            if (obj.label === label) return obj;
        }
    }

    getObjectByLabel(label: string): ProjectObjectImpl | undefined {
        const tokens = label.split('.');
        if (tokens.length > 2) throw new Error(`invalid label "${label}"`);
        const object = this.getTopObjectByLabel(tokens[0]!);
        if (!object) return;
        if (tokens.length === 2) {
            const childId = object.init.outlets[tokens[1]!]?.firstStatic;
            return childId ? this.getObjectById(childId) : undefined;
        } else {
            return object;
        }
    }

    withoutI18nLang(code: string): ProjectImpl {
        return new ProjectImpl({ ...this.data, i18n: this.data.i18n.withoutLanguage(code) });
    }

    withI18nLang(lang: I18nLang): ProjectImpl {
        return new ProjectImpl({ ...this.data, i18n: this.data.i18n.withLanguage(lang) });
    }

    editI18nLang(lang: I18nLang): ProjectImpl {
        return new ProjectImpl({ ...this.data, i18n: this.data.i18n.editLanguage(lang) });
    }

    getObjectsByTag(tag: string): ProjectObjectImpl[] {
        return Object.values(this.objects).filter((o) => o.tags.includes(tag)); // TODO children
    }

    getObjectsByUserType(type: string): ProjectObjectImpl[] {
        return Object.values(this.objects).filter((o) => o.userType === type); // TODO children
    }

    hasObjectWithLabel(name: string): boolean {
        return !!this.getObjectByLabel(name);
    }

    resolveRootEntity(self: ObjectID, rootRef: ObjectID) {
        return this.getObjectById(rootRef === REF_SELF ? self : rootRef);
    }

    withTagCategory(category: string, color: string, multiple: boolean, values: string[]) {
        return new ProjectImpl({ ...this.data, tags: this.tags.withCategory(category, color, multiple, values) });
    }

    withOutTagCategory(categoryName: string) {
        return new ProjectImpl({ ...this.data, tags: this.tags.removeCategory(categoryName) });
    }

    withTag(tag: string, include = true) {
        return new ProjectImpl({ ...this.data, tags: this.tags.withTag(tag, include) });
    }

    withOutTag(tag: string, categoryName: string) {
        return new ProjectImpl({ ...this.data, tags: this.tags.removeTag(tag, categoryName) });
    }

    withComponentEntry(ce: ComponentEntry, objectProcessor?: (obj: ProjectObjectImpl) => ProjectObjectImpl): ProjectImpl {
        const objects = { ...this.data.objects };

        const _objectProcessor = objectProcessor || ((obj: ProjectObjectImpl) => obj);

        function addAllRelatedObjects(oe: ObjectEntry) {
            objects[oe.object.uuid] = _objectProcessor(oe.object);
            for (const companion of oe.createdObjects) {
                const object = companion.object;
                objects[object.uuid] = _objectProcessor(object);
                addAllRelatedObjects(companion);
            }
        }

        for (const objectEntry of ce.objects) {
            addAllRelatedObjects(objectEntry);
        }

        let p = new ProjectImpl({
            ...this.data,
            modules: { ...this.data.modules, [ce.component.uuid]: ce.component },
            objects: objects,
        });

        // now we recursively add auto-created components
        for (const objectEntry of ce.objects) {
            for (const autoCreatedComponentEntry of objectEntry.createdComponents) {
                p = p.withComponentEntry(autoCreatedComponentEntry);
            }
        }

        return p;
    }

    withObject(object: ProjectObjectImpl): ProjectImpl {
        return new ProjectImpl({ ...this.data, objects: { ...this.objects, [object.uuid]: object } });
    }

    withoutComponent(componentId: string): ProjectImpl {
        // remove module
        const modules = { ...this.data.modules };
        if (!modules[componentId]) return this;
        delete modules[componentId];

        // remove objects from this module
        const objects = { ...this.data.objects };
        for (const obj of Object.values(objects)) {
            if (obj.impl.componentRef.componentId === componentId) {
                delete objects[obj.uuid];
            }
        }

        // remove mapping to hardware
        const hardware = this.hardware.withoutMapping(componentId);

        return new ProjectImpl({ ...this.data, modules, objects, hardware });
    }

    withHardware(hardware: ProjectHardwareImpl) {
        return new ProjectImpl({
            ...this.data,
            hardware,
        });
    }

    withSecurity(iam: ProjectSecurityImpl) {
        return new ProjectImpl({
            ...this.data,
            security: iam,
        });
    }

    withLabel(label: string) {
        return new ProjectImpl({
            ...this.data,
            label,
        });
    }
}
