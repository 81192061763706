import { Command, CommandHandler } from './command-handler';
import log from 'loglevel';

export class CommandDispatcher {
    constructor(private handlers: CommandHandler<any>[]) {}

    execute<T extends Command>(cmd: T, onFinish?: () => void) {
        const h = this.handlers.find((h) => h.supports(cmd));
        if (h) {
            try {
                const result = h.execute(cmd);
                if (result instanceof Promise) {
                    result
                        .then(() => {
                            if (onFinish) onFinish();
                        })
                        .catch((e) => {
                            log.error(`error executing async command ${cmd.type}`, e);
                        });
                } else {
                    if (onFinish) onFinish();
                }
            } catch (e) {
                log.error(`error executing command ${cmd.type}`, e);
            }
        } else {
            log.warn(`command handler not found for ${cmd.type}`);
        }
    }
}
