import { Command, CommandHandler } from '../../dispatcher';
import { StateUpdater } from '../../utils/state';
import { ProjectImpl } from '../model';

export interface RemoveTagCategoryForm {
    name: string;
}

export class RemoveTagCategoryCommand implements Command {
    readonly type = 'RemoveTagCategoryCommand';
    constructor(readonly data: { form: RemoveTagCategoryForm }) {}
}

export class RemoveTagCategoryCommandHandler implements CommandHandler<RemoveTagCategoryCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'RemoveTagCategoryCommand';
    }

    execute(cmd: RemoveTagCategoryCommand): void {
        this.update((project) => project.withOutTagCategory(cmd.data.form.name));
    }
}
