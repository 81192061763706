import { Command, CommandHandler } from '../../dispatcher';
import { ProjectImpl } from '../../project';
import { StateUpdater } from '../../utils/state';

export class RemoveCluCommand implements Command {
    readonly type = 'RemoveCluCommand';
    constructor(readonly data: { cluId: string }) {}
}

export class RemoveCluCommandHandler implements CommandHandler<RemoveCluCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command) {
        return cmd.type === 'RemoveCluCommand';
    }

    execute(cmd: RemoveCluCommand) {
        this.update((p) => {
            const clu = p.hardware.configuration.clus.find((c) => c.id === cmd.data.cluId);
            if (!clu) return p;
            const clus = p.hardware.configuration.clus.filter((c) => c !== clu);
            let hardware = p.hardware.withConfiguration({ clus });
            for (const module of clu.modules) {
                const moduleId = hardware.findVirtualMappedToHardware(module.id);
                if (moduleId) hardware = hardware.withoutMapping(moduleId);
            }
            return p.withHardware(hardware);
        });
    }
}
