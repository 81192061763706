import { Block, Workspace, serialization } from 'blockly';
import { BehaviorSubject, combineLatest, filter, map, Observable } from 'rxjs';
import { ActionScriptImpl, ProjectImpl, ActionItemImpl, lua_pathToCode, StateProvider, resolvePath } from '@grenton/gm-logic';
import { actionRepo } from './action-repo';
import { ScriptWithContext } from '@grenton/gm-logic';
import { ObjectScriptRef } from '@grenton/gm-logic';
import { ModeController } from '../backend/abstractModeController';
import { updateBlocksToReflectProjectState } from '../visual/updateBlocksToReflectProjectState';
import { ActionOutput, ObjectApi } from '@grenton/gm-common';
import { generateBlocksForMethodCalls } from './code';
import { ActionItemUI } from './action-item-ui';
import { ResolvedPath } from '@grenton/gm-logic';
import { apiIcon, functionalTypeIconResolver } from '@grenton/gm/shared';
import { BlockCreator } from '../visual/blockCreator';
import { luaGenerator } from 'blockly/lua';

export class ActionModeController implements ModeController<ActionScriptImpl> {
    readonly actions = new BehaviorSubject<ActionScriptImpl | null>(null);
    readonly actionRows: Observable<ActionItemUI[]>;

    private scriptContextRef?: ObjectScriptRef;

    constructor(
        private project: StateProvider<ProjectImpl | null>,
        private onSave: (actions: ActionScriptImpl | null) => void,
    ) {
        this.actionRows = combineLatest([this.project, this.actions]).pipe(
            filter(([p]) => !!p),
            map(
                ([project, actions]) =>
                    actions?.items
                        .map((item) => ({
                            item,
                            // at this point, all relative paths should start with 'self', but we still need to provide 'self' object
                            resolved: resolvePath(item.target, project!.objectResolver, this.scriptContextRef?.objectId!),
                        }))
                        .filter(({ resolved }) => Boolean(resolved))
                        .map(({ item, resolved }) => {
                            const availableTypes = actionRepo.types(resolved);

                            return {
                                action: item.action,
                                availableTypes,
                                resolvedTarget: {
                                    path: resolved.serialize(),
                                    api: resolved.outputApi!,
                                    name: lua_pathToCode(resolved, false),
                                    device: resolved.rootObject?.userType,
                                },
                            } as ActionItemUI;
                        }) || [],
            ),
        );
    }

    onScriptChange(scriptContext: ScriptWithContext): void {
        this.scriptContextRef = scriptContext.ref;
        this.actions.next(scriptContext.getScript()?.actions || null);
    }

    setContent(script: ActionScriptImpl | null) {
        this.actions.next(script);
    }

    onDeleteAction(index: number) {
        const actions = this.actions.value;
        if (!actions) return;
        let updated = actions.withoutItem(index);
        updated = this.generateMethodCalls(updated);
        this.setContent(updated);
        this.onSave(updated);
    }

    onChangeType(index: number, type: string) {
        const actions = this.actions.value;
        if (!actions) return;
        const actionTemplate = actionRepo.action(type);
        if (!actionTemplate) return;

        let updated = actions.withItemUpdate(index, (item: ActionItemImpl) => item.withSelectedType(type, actionTemplate.defaults));
        updated = this.generateMethodCalls(updated);
        this.setContent(updated);
        this.onSave(updated);
    }

    onUpdate(index: number, item: ActionItemImpl) {
        const actions = this.actions.value;
        if (!actions) return;
        let updated = actions.withItemUpdate(index, () => item);
        updated = this.generateMethodCalls(updated);
        this.setContent(updated);
        this.onSave(updated);
    }

    onUpdateSpecificAction(index: number, type: string, model: any) {
        const actions = this.actions.value;
        if (!actions) return;
        let updated = actions.withItemUpdate(index, (item) => item.withAction({ type, model }));
        updated = this.generateMethodCalls(updated);
        this.setContent(updated);
        this.onSave(updated);
    }

    generateMethodCalls(actions: ActionScriptImpl) {
        const p = this.project.value;
        const selfID = this.scriptContextRef?.objectId;
        if (!selfID) return actions;
        let _actions = actions;

        actions?.items?.forEach((_, index) => {
            _actions = _actions.withItemUpdate(index, (item) => {
                const path = p ? resolvePath(item.target, p.objectResolver, selfID) : null;
                const actionRecipe = actionRepo.action(item.selectedType);
                const output: ActionOutput = actionRecipe && path ? actionRecipe.generator(item.action.model, path) : { calls: [] };
                return item.withOutput(output);
            });
        });
        return _actions;
    }

    generateDerived() {
        if (!this.scriptContextRef) return;
        const p = this.project.value;
        if (!p) return;
        return generateBlocklyForActions(p, this.scriptContextRef, this.actions.value);
    }

    // this editor handles only objects and outlets (api entities)
    /*
     * when we edit a handler of module object, we actually edit {id}$ctrl object (=this.scriptContextRef.objectId)
     * so first of all, SecondaryTree must display {id} object as 'self, not $ctrl.
     * then, when it is clicked, we need to discover situation that {id}$ctrl.object = {id}, and translate path into self.object
     */
    addScriptlet(relative: ResolvedPath) {
        const tail = relative.tail;

        switch (tail?.type) {
            case 'object':
            case 'outlet':
                let actions = this.actions.value || new ActionScriptImpl({ items: [] });
                // check if there's already action related to this target
                if (actions.items.find((item) => relative.isSame(item.target))) return;
                const types = actionRepo.typesForApi(relative.outputApi!);
                if (!types.length) return;
                actions = actions.withItem(
                    ActionItemImpl.fromAction(relative.serialize(), {
                        type: types[0]!.type,
                        model: types[0]!.defaults,
                    }),
                );
                this.setContent(actions);
                this.onSave(actions);
                break;
        }
    }

    actionTargetIconResolver() {
        const functionalTypeIcon = functionalTypeIconResolver(this.project.value!.firmware);
        return (target: { api: ObjectApi; device?: string }) => {
            return (target.device ? functionalTypeIcon(target.device) : null) || apiIcon(target.api) || null;
        };
    }
}

function generateBlocklyForActions(
    p: ProjectImpl,
    objectScriptRef: ObjectScriptRef,
    script: ActionScriptImpl | null,
): { visual: Record<string, object> | null; lua: string | null } {
    const selfID = objectScriptRef.objectId;
    const workspace = new Workspace();
    const creator = new BlockCreator(workspace);

    let prevBlock: Block | undefined;
    let varCount = 1;
    const actionBlocks: Block[] = [];

    script?.items?.map((item) => {
        const path = p ? resolvePath(item.target, p.objectResolver, selfID) : null;

        if (!path || path.empty) return;

        const actionRecipe = actionRepo.action(item.selectedType);
        if (actionRecipe) {
            const output: ActionOutput = actionRecipe.generator(item.action.model, path);
            const blocks = generateBlocksForMethodCalls(output.calls, path, creator, path.outputApi!);
            actionBlocks.push(...blocks);
        }
    });

    // we need to call it here to reshape all blocks, otherwise we cannot make appropriate connections between them
    updateBlocksToReflectProjectState(workspace, p, objectScriptRef);

    actionBlocks
        .filter((b) => !!b)
        .map((b) => b!)
        .forEach((actionBlock) => {
            if (actionBlock.outputConnection) {
                const resultVariable = workspace.createVariable(`result_${varCount++}`);
                const resultVariableBlock = creator.newBlock<Block>('variables_set', (b) => {
                    b.setFieldValue(resultVariable.getId(), 'VAR');
                });
                resultVariableBlock.getInput('VALUE')?.connection?.connect(actionBlock.outputConnection);
                actionBlock = resultVariableBlock;
            }

            if (actionBlock.previousConnection && actionBlock.previousConnection) {
                if (prevBlock?.nextConnection) {
                    prevBlock.nextConnection.connect(actionBlock.previousConnection);
                }
                prevBlock = actionBlock;
            }
            // TODO fill with arguments
        });

    const workspaceJson = serialization.workspaces.save(workspace);
    //const visual = Object.entries(workspaceJson).length ? JSON.stringify(workspaceJson) : null;
    return {
        visual: Object.entries(workspaceJson).length ? workspaceJson : null,
        lua: luaGenerator.workspaceToCode(workspace),
    };
}
