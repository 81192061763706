import { Components, Theme } from '@mui/material';
import { spacing } from '../size';

export const MuiDialogContent: Components<Omit<Theme, 'components'>>['MuiDialogContent'] = {
    defaultProps: {},
    styleOverrides: {
        //@ts-ignore
        root: ({ _ownerState, _theme }) => ({
            padding: spacing.M,
        }),
    },
};
