import {
    RxDatabase,
    createRxDatabase,
    toTypedRxJsonSchema,
    addRxPlugin,
    ExtractDocumentTypeFromTypedRxJsonSchema,
    RxJsonSchema,
    RxCollection,
    RxDocument,
    createBlob,
    RxReplicationPullStreamItem,
    RxConflictHandlerInput,
    RxConflictHandlerOutput,
    WithDeletedAndAttachments,
    deepEqual,
} from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxReplicationState, replicateRxCollection } from 'rxdb/plugins/replication';

import { Project } from '@grenton/gm-common';
import { ProjectRepository } from '@grenton/gm-logic';
import appEnv from '@grenton/gm/app-env';
import { TokenHolder } from '@grenton/gm/auth/tokenholder';
import { Observable, Subject } from 'rxjs';
import { fetchEventSource } from '@microsoft/fetch-event-source';

if (appEnv.MODE === 'development') {
    addRxPlugin(RxDBDevModePlugin);
}
addRxPlugin(RxDBAttachmentsPlugin);

const MAX_REVISIONS_TO_SAVE = 20;
const projectSchemaLiteral = {
    version: 0,
    primaryKey: 'id',
    indexes: ['uid', 'updatedAt', 'lastSyncAt'],
    type: 'object',
    properties: {
        // configuration id
        id: {
            type: 'string',
            maxLength: 100, // <- the primary key must have set maxLength
        },
        // backend internal id
        backendId: {
            type: 'string',
            maxLength: 100,
        },
        // user id (sub claim)
        uid: {
            type: 'string',
            maxLength: 100,
        },
        // name of the configuration
        name: {
            type: 'string',
        },
        // schema of the configuration (to help with migration)
        schema: {
            type: 'string',
        },
        // who created the configuration
        createdBy: {
            type: 'object',
            properties: {
                email: {
                    type: 'string',
                    maxLength: 100,
                },
                uid: {
                    type: 'string',
                    maxLength: 100,
                },
            },
        },
        // updated at
        updatedAt: {
            type: 'string',
            format: 'date-time',
            maxLength: 32,
        },
        // last sync at
        lastSyncAt: {
            type: 'integer',
            multipleOf: 1,
            minimum: 0,
            maximum: Number.MAX_SAFE_INTEGER,
        },
        revisions: {
            type: 'array',
            maxItems: MAX_REVISIONS_TO_SAVE,
            uniqueItems: true,
            items: {
                type: 'object',
                properties: {
                    tag: {
                        type: 'string',
                    },
                    author: {
                        type: 'string',
                    },
                    ts: {
                        type: 'string',
                        format: 'date-time',
                    },
                    note: {
                        type: 'string',
                    },
                },
            },
        },
    },
    attachments: {
        encrypted: false,
    },
    required: ['id', 'uid', 'name', 'createdBy', 'updatedAt'],
} as const;

const projectTyped = toTypedRxJsonSchema(projectSchemaLiteral);
export type ProjectDocType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof projectTyped>;
export const todoSchema: RxJsonSchema<ProjectDocType> = projectSchemaLiteral;

export type ProjectDocMethods = {
    scream: (v: string) => string;
};
export type ProjectDocument = RxDocument<ProjectDocType, ProjectDocMethods>;

export type ProjectCollectionMethods = {
    countAllDocuments: () => Promise<number>;
};

// and then merge all our types
export type ProjectCollection = RxCollection<ProjectDocType, ProjectDocMethods, ProjectCollectionMethods>;

export type GmDatabaseCollections = {
    projects: ProjectCollection;
};

type Checkpoint = Readonly<{ lastSyncAt: number; backendId: string }>;

export class ProjectRxDatabaseRepository implements ProjectRepository {
    private _db?: RxDatabase<GmDatabaseCollections, any, any, unknown>;

    private replication?: RxReplicationState<ProjectDocType, Checkpoint>;
    private pullStreamAbortController?: AbortController;
    private pullStream = new Subject<RxReplicationPullStreamItem<ProjectDocType, Checkpoint>>();

    constructor(private tokenHolder: TokenHolder) {}

    get db() {
        if (!this._db) {
            throw new Error('db not initialized!');
        }
        return this._db;
    }

    async init() {
        const db = await createRxDatabase<GmDatabaseCollections>({
            ignoreDuplicate: true, //to enable hot-reload
            name: 'gm-rxdb-6',
            storage: getRxStorageDexie(),
        });
        await db.addCollections({ projects: { schema: projectSchemaLiteral, conflictHandler: this.handleConflict.bind(this) } });
        this._db = db;

        this.tokenHolder.token.subscribe(async (token) => {
            if (!appEnv.CLOUD_SYNC_ENABLED) {
                await this.replication?.remove();
                this.pullStreamAbortController?.abort();
                return;
            }

            // cancel replication if user is not authenticated
            if (!token) {
                await this.replication?.remove();
                this.pullStreamAbortController?.abort();
                return;
            }

            // cancel replication if it is already running
            await this.replication?.remove();
            this.pullStreamAbortController?.abort();

            this.pullStreamAbortController = new AbortController();
            const pullStream = this.pullStream;
            fetchEventSource(appEnv.CLOUD_SYNC_PULL_STREAM_URL, {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                },
                signal: this.pullStreamAbortController.signal,
                async onopen(response: Response) {
                    if (response.ok) {
                        pullStream.next('RESYNC');
                    }
                },
                onmessage(event) {
                    if (!event.data) return;
                    const eventData = JSON.parse(event.data);
                    pullStream.next({
                        documents: eventData.documents,
                        checkpoint: eventData.checkpoint,
                    });
                },
                onerror() {
                    //retry after 5 seconds
                    return 5000;
                },
            });

            // start replication
            this.replication = await replicateRxCollection<ProjectDocType, Checkpoint>({
                collection: db.projects,
                replicationIdentifier: `projects-${appEnv.CLOUD_SYNC_URL}`,
                push: {
                    async handler(changeRows) {
                        const rawResponse = await fetch(appEnv.CLOUD_SYNC_URL, {
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${token.accessToken}`,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(changeRows),
                        });
                        const conflictsArray = await rawResponse.json();
                        return conflictsArray;
                    },
                },
                pull: {
                    async handler(checkpointOrNull: Checkpoint | undefined, batchSize: number) {
                        const lastSyncAt = checkpointOrNull ? checkpointOrNull.lastSyncAt : 0;
                        const backendId = checkpointOrNull ? `&backendId=${checkpointOrNull.backendId}` : '';
                        const response = await fetch(`${appEnv.CLOUD_SYNC_URL}?lastSyncAt=${lastSyncAt}${backendId}&limit=${batchSize}`, {
                            headers: {
                                Authorization: `Bearer ${token.accessToken}`,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        });
                        const data = await response.json();

                        return {
                            documents: data.documents,
                            checkpoint: data.checkpoint,
                        };
                    },
                    stream$: this.pullStream.asObservable(),
                },
            });

            if (appEnv.MODE === 'development') {
                this.replication?.subjects.received.subscribe((item) => {
                    console.log('received', item);
                });
                this?.replication?.subjects.sent.subscribe((item) => {
                    console.log('sent', item);
                });
                this.replication?.subjects.error.subscribe((item) => {
                    console.log('error', item);
                });
            }
        });

        return true;
    }

    private async handleConflict(input: RxConflictHandlerInput<ProjectDocType>): Promise<RxConflictHandlerOutput<ProjectDocType>> {
        // hacky, but we need to compare attachments, as modyfing them doesn't update the updatedAt timestamp
        const withAttachments: {
            assumedMasterState?: WithDeletedAndAttachments<ProjectDocType>;
            realMasterState: WithDeletedAndAttachments<ProjectDocType>;
            newDocumentState: WithDeletedAndAttachments<ProjectDocType>;
        } = input;

        if (input.newDocumentState.updatedAt === input.realMasterState.updatedAt && input.newDocumentState.lastSyncAt === input.realMasterState.lastSyncAt) {
            // check if attachments equal
            if (deepEqual(withAttachments.newDocumentState._attachments, withAttachments.realMasterState._attachments)) {
                return {
                    isEqual: true,
                };
            } else {
                return {
                    isEqual: false,
                    documentData: input.newDocumentState,
                };
            }
        }

        // it may be that we just need to update lastSyncAt
        if (input.newDocumentState.updatedAt === input.realMasterState.updatedAt) {
            input.newDocumentState.lastSyncAt = input.realMasterState.lastSyncAt;
            input.newDocumentState.backendId = input.newDocumentState.backendId ?? input.realMasterState.backendId;
            return {
                isEqual: false,
                documentData: input.newDocumentState,
            };
        }

        return {
            isEqual: false,
            documentData: input.realMasterState,
        };
    }

    async loadConfiguration(id: string) {
        const p = await this.db.projects.findOne(id).exec();
        if (!p) throw new Error('project not found');
        const configuration = p.getAttachment('configuration.json');
        if (!configuration) throw new Error('configuration attachment not found');
        const json = await (await configuration.getData()).text();
        return JSON.parse(json) as Project;
    }

    async getAllByUser(userId: string): Promise<ProjectDocType[]> {
        if (!userId) throw new Error('userId is required');
        return this.db.projects
            .find({
                selector: {
                    uid: {
                        $eq: userId,
                    },
                },
                sort: [
                    {
                        updatedAt: 'desc',
                    },
                ],
            })
            .exec();
    }

    observeAllByUser(userId: string): Observable<ProjectDocType[]> {
        if (!userId) throw new Error('userId is required');
        return this.db.projects.find({
            selector: {
                uid: {
                    $eq: userId,
                },
            },
            sort: [
                {
                    updatedAt: 'desc',
                },
            ],
        }).$;
    }

    async store(userId: string, email: string, project: Project): Promise<void> {
        if (!userId) throw new Error('user is required');
        const doc = await this.db.projects.upsert({
            id: project.id,
            uid: userId,
            name: project.label || 'unnamed',
            schema: project.$schema,
            createdBy: { email, uid: userId },
            updatedAt: new Date().toISOString(),
            revisions: project.revisions.toSpliced(MAX_REVISIONS_TO_SAVE),
        });

        await doc.putAttachment({
            id: 'configuration.json',
            data: createBlob(JSON.stringify(project), 'text/plain'), // (string|Blob) data of the attachment
            type: 'text/plain',
        });
    }

    async delete(id: string) {
        await this.db.projects.findOne(id).remove();
    }
}
