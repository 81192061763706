import { HWConfigurationShim } from '@grenton/gm-common';
import { Command, CommandHandler } from '../../dispatcher';
import { ProjectImpl, mergeHWConfigurations } from '../../project';
import { StateUpdater } from '../../utils/state';

export class StoreHardwareConfigurationCommand implements Command {
    readonly type = 'StoreHardwareConfiguration';

    constructor(readonly data: { configuration: HWConfigurationShim }) {}
}

export class StoreHardwareConfigurationHandler implements CommandHandler<StoreHardwareConfigurationCommand> {
    constructor(private update: StateUpdater<ProjectImpl>) {}

    supports(cmd: Command): boolean {
        return cmd.type === 'StoreHardwareConfiguration';
    }
    execute(cmd: StoreHardwareConfigurationCommand): void {
        this.update((p) => p.withHardware(p.hardware.withConfiguration(mergeHWConfigurations(p.hardware.configuration, cmd.data.configuration))));
    }
}
